import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  MenuItem,
  Select,
  TableCell,
  TableSortLabel,
  Input,
  Table,
  TableRow,
  TableHead,
  Button,
  TableBody,
  CardActions,
  CircularProgress,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import {
  toCurrency, isSortFieldActive, getSortIcon, openNewTab,
} from '../../helper';
import {
  DEFAULT_PAGE_SIZE_OPTIONS,
  RXTABLEFIELD_PAGE_SIZE,
  TABLE_SORT_ASCENDING,
  TABLE_SORT_DESCENDING,
  TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO,
  TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT,
  RXTABLEFIELD_ACTIVE_PAGE,
  CART_SERVICE_NAME_IMPORT,
  CART_SERVICE_NAME_EXPORT,
  DEFAULT_STYLES,
  NOT_AVAILABLE,
  PAYMENT_TYPE_CASH,
  PAYMENT_STATUS_PENDING,
} from '../../constant';
import { PdfViewer } from '../../component';
import InvoiceInfoDialog from './invoice-info-dialog.container';

const styles = theme => ({
  btnKmt: {
    marginLeft: 8,
  },
  btnSp2: {
    marginLeft: 8,
    backgroundColor: '#ffffff',
  },
  btnInvoice: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  cellAction: {
    width: '128px',
  },
  cardActionPrimary: {
    display: 'flex',
    alignItems: 'baseline',
    padding: 16,
    justifyContent: 'space-between',
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  cardBlDataHeader: {
    marginBottom: theme.spacing.unit * 2,
  },
  detailHeader: {
    color: '#0066CB',
  },
  itemPerRowContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noAccess: {
    fontStyle: 'italic',
    // textAlign: 'center',
    color: 'rgb(175, 168, 168)',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    marginTop: '24px',
  },
  tablePaginationPageInput: {
    flexGrow: 1,
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'baseline',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  circularProgress: {
    height: '24px !important',
    width: '24px !important',
  },
});

const HistoryDetail = ({
  classes,
  selectedBl,
  selectedBlItems,
  userCompany,

  activePage,
  totalPage,
  filters,
  sortField,
  sortDirection,
  pageSize,
  downloadingInvoiceInfo,

  onAppear,
  onInvoicePressed,
  onKmtPressed,
  onSp2Pressed,
  onCancelPaymentPressed,
  onChangePaymentPressed,
  onChangeSort,
  onChangePaging,
  onChangeFilter,
}) => {
  useEffect(onAppear, []);

  if (!selectedBl) {
    return null;
  }

  const totalAmount = selectedBl.items.reduce((acc, curr) => acc + curr.amount, 0);

  const handleChangeSort = (newSortField) => {
    if (newSortField === sortField) {
      onChangeSort(
        sortField,
        sortDirection === TABLE_SORT_ASCENDING
          ? TABLE_SORT_DESCENDING
          : TABLE_SORT_ASCENDING,
      );
    } else {
      onChangeSort(newSortField, TABLE_SORT_ASCENDING);
    }
  };

  const renderTableCellHeader = (label, fieldId) => (
    <TableCell>
      <TableSortLabel
        active={
          isSortFieldActive(sortField, fieldId)
        }
        IconComponent={getSortIcon(
          isSortFieldActive(sortField, fieldId),
          sortDirection,
        )}
        onClick={
          () => handleChangeSort(fieldId)
        }
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const renderTableCellStringFilter = field => (
    <TableCell>
      <Input
        type="text"
        fullWidth
        value={filters[field]}
        onChange={
          ({ nativeEvent }) => onChangeFilter(
            field,
            nativeEvent.target.value,
          )
        }
        placeholder={LocalizedString.common.placeholderSearch}
      />
    </TableCell>
  );

  const renderKmtSp2Button = (item) => {
    if (item.service === CART_SERVICE_NAME_IMPORT) {
      // render SP2
      if (userCompany.id !== selectedBl.createdByCompany) {
        return null;
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          classes={{ outlinedPrimary: classes.btnSp2 }}
          disabled={!item.invoiceNo}
          onClick={() => onSp2Pressed(item)}
        >
          {LocalizedString.myPaymentHistory.captionSp2Pdf}
        </Button>
      );
    }
    if (item.service === CART_SERVICE_NAME_EXPORT) {
      if (userCompany.id !== selectedBl.createdByCompany) {
        return null;
      }
      return (
        <Button
          variant="outlined"
          color="primary"
          classes={{ outlinedPrimary: classes.btnKmt }}
          disabled={!item.invoiceNo}
          onClick={() => onKmtPressed(item)}
        >
          {LocalizedString.myPaymentHistory.captionKmtPdf}
        </Button>
      );
    }

    return null;
  };

  const renderTableCellAction = item => (
    <TableCell>
      <div style={{ display: 'flex' }}>
        {downloadingInvoiceInfo ? (
          <CircularProgress className={classes.circularProgress} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.btnInvoice}
            disabled={!item.invoiceNo}
            onClick={() => onInvoicePressed(item)}
          >
            {LocalizedString.myPaymentHistory.captionInvoicePdf}
          </Button>
        )}
        {renderKmtSp2Button(item)}
      </div>
    </TableCell>
  );

  const renderItemContent = (item) => {
    if (item.blNumber === NOT_AVAILABLE) {
      return (
        <TableRow>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.noAccess}>
              {LocalizedString.common.labelNoAccess}
            </Typography>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell>{item.blNumber}</TableCell>
        <TableCell>{item.service}</TableCell>
        <TableCell>{item.invoiceNo || '-'}</TableCell>
        <TableCell>{item.paymentOnBehalfOf || '-'}</TableCell>
        <TableCell>{toCurrency(item.amount)}</TableCell>
        {renderTableCellAction(item)}
      </TableRow>
    );
  };

  const renderCardAction = () => {
    if (!selectedBl.paidOn && selectedBl.paymentCode && onCancelPaymentPressed
      && onChangePaymentPressed) {
      return (
        <CardActions className={classes.cardActionPrimary}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%',
          }}
          >
            <Button
              variant="text"
              color="secondary"
              onClick={() => onCancelPaymentPressed(selectedBl)}
            >
              {LocalizedString.myPaymentHistory.captionCancelPayment}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              classes={{ outlinedPrimary: classes.btnSp2 }}
              onClick={() => onChangePaymentPressed(selectedBl, userCompany.staticVa)}
            >
              {LocalizedString.myPaymentHistory.captionChangePayment}
            </Button>
          </div>
        </CardActions>
      );
    }
    return (<></>);
  };

  return (
    <>
      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <div className={classes.headerContainer}>
              <Typography variant="h6" className={classes.detailHeader}>
                {LocalizedString.myPaymentHistory.labelProformaNo}
                &nbsp;
                {selectedBl.proformaNo}
              </Typography>
              {selectedBl.paymentMethod === PAYMENT_TYPE_CASH
              && selectedBl.paymentStatus === PAYMENT_STATUS_PENDING && selectedBl.paymentUrl && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnInvoice}
                  classes={{ outlinedPrimary: classes.btnSp2 }}
                  onClick={() => openNewTab(selectedBl.paymentUrl)}
                >
                  {LocalizedString.myPaymentHistory.buttonCaptionPay}
                </Button>
              )}
            </div>
          )}
        />
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={6} md={2}>
              <Typography variant="caption">
                {LocalizedString.myPaymentHistory.labelAmount}
              </Typography>
              <Typography>
                {toCurrency(totalAmount)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="caption">
                {LocalizedString.myPaymentHistory.labelItem}
              </Typography>
              <Typography>
                {selectedBl.items.length}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography variant="caption">
                {LocalizedString.myPaymentHistory.labelMethod}
              </Typography>
              <Typography>
                {selectedBl.paymentMethod}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">
                {LocalizedString.myPaymentHistory.labelChannel}
              </Typography>
              <Typography>
                {selectedBl.paymentChannel}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="caption">
                {LocalizedString.myPaymentHistory.labelVaNumber}
              </Typography>
              <Typography>
                {selectedBl.paymentCode}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        {renderCardAction()}
      </Card>

      <Card className={classes.cardBlDataHeader}>
        <CardHeader
          title={(
            <>
              <Typography variant="h6" className={classes.detailHeader}>
                Detail
              </Typography>
              <div className={classes.itemPerRowContainer}>
                <Select
                  value={pageSize}
                  onChange={event => onChangePaging(
                    RXTABLEFIELD_PAGE_SIZE,
                    event.target.value,
                  )}
                >
                  {DEFAULT_PAGE_SIZE_OPTIONS.map(item => (
                    <MenuItem value={item} key={item}>
                      {item}
                      &nbsp;
                      {LocalizedString.common.labelRows}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}
        />
        <CardContent>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {renderTableCellHeader(LocalizedString.myPaymentHistory.labelBlNumber,
                    TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER)}
                  {renderTableCellHeader(LocalizedString.myPaymentHistory.labelService,
                    TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE)}
                  {renderTableCellHeader(LocalizedString.myPaymentHistory.labelInvoiceNo,
                    TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER)}
                  {renderTableCellHeader(LocalizedString.myPaymentHistory.labelInvoiceTo,
                    TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO)}
                  {renderTableCellHeader(LocalizedString.myPaymentHistory.labelAmount,
                    TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT)}
                  <TableCell>
                    {LocalizedString.common.labelActions}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {renderTableCellStringFilter(TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER)}
                  {renderTableCellStringFilter(TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE)}
                  {renderTableCellStringFilter(TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER)}
                  {renderTableCellStringFilter(TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO)}
                  {renderTableCellStringFilter(TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT)}
                  <TableCell className={classes.cellAction} />
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedBlItems.map(item => renderItemContent(item))}
              </TableBody>
            </Table>
          </div>

          <div className={classes.tablePagination}>
            <Button
              variant="contained"
              style={{ flexGrow: 1 }}
              disabled={activePage === 1}
              onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage - 1)}
            >
              {LocalizedString.common.labelPagingPrevButton}
            </Button>

            <div className={classes.tablePaginationPageInput}>
              <span style={{ fontWeight: 500 }}>{LocalizedString.common.labelPagingPage}</span>
              <Input
                type="number"
                value={activePage}
                style={{ width: '50%' }}
                inputProps={{
                  min: 1,
                  max: totalPage,
                  step: 1,
                  style: { textAlign: 'center' },
                }}
                onChange={({ nativeEvent }) => onChangePaging(
                  RXTABLEFIELD_ACTIVE_PAGE,
                  parseInt(nativeEvent.target.value, 10),
                )}
              />
              <span style={{ fontWeight: 500 }}>
                {`${LocalizedString.common.labelPagingPageOf} ${totalPage}`}
              </span>
            </div>

            <Button
              variant="contained"
              style={{ flexGrow: 1 }}
              disabled={activePage === totalPage}
              onClick={() => onChangePaging(RXTABLEFIELD_ACTIVE_PAGE, activePage + 1)}
            >
              {LocalizedString.common.labelPagingNextButton}
            </Button>
          </div>
        </CardContent>
      </Card>

      <PdfViewer />

      <InvoiceInfoDialog />
    </>
  );
};

HistoryDetail.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  selectedBl: PropTypes.shape(PropTypes.any).isRequired,
  selectedBlItems: PropTypes.shape(PropTypes.any).isRequired,
  userCompany: PropTypes.shape(PropTypes.any).isRequired,

  filters: PropTypes.shape(PropTypes.any).isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  downloadingInvoiceInfo: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onInvoicePressed: PropTypes.func.isRequired,
  onKmtPressed: PropTypes.func.isRequired,
  onSp2Pressed: PropTypes.func.isRequired,
  onCancelPaymentPressed: PropTypes.func.isRequired,
  onChangePaymentPressed: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangePaging: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(HistoryDetail);
