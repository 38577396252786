import React from 'react';
import {
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
  browserName,
  fullBrowserVersion,
} from 'react-device-detect';
import uuid from 'uuid/v4';
import _ from 'lodash';
import {
  InputLabel, Input, FormHelperText, FormControlLabel, Checkbox, TextField, Icon, Typography,
} from '@material-ui/core';
import { InlineDatePicker, DatePicker, DateTimePicker } from 'material-ui-pickers';
import moment from 'moment-timezone';
import * as Constant from './constant';
import {
  FormControl, FilterableSelect, FilterableSelectAsync, FilterableSelectCreateAsync, FilePickerField,
} from './component';
import LocalizedString from './localization';

export class HttpError extends Error {
  status = null;

  constructor(error) {
    super(error.message);
    this.status = error.status;
  }
}

const getHttpHeaders = (authenticationToken) => {
  let headers = {
    'Content-Type': Constant.HTTP_HEADER_VALUE_JSON,
    'X-DeviceId': 'WebApp',
    'X-DeviceManufacturer': mobileVendor,
    'X-DeviceModel': mobileModel,
    'X-OSName': osName,
    'X-OSVersion': osVersion,
    'X-AppVersion': 'WebApp',
    'X-Notes': `Browser: ${browserName} v${fullBrowserVersion}`,
  };

  if (authenticationToken) {
    headers = { ...headers, Authorization: authenticationToken };
  }

  return headers;
};

const sendGetRequest = async (apiPath, authenticationToken) => {
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_GET;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });
  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }
  throw new HttpError({ status: response.status, message: responseText });
};

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    const pattern = /data[:][^/]+\/[^;]+;base64,/gi;
    resolve(reader.result.replace(pattern, ''));
  };
  reader.readAsDataURL(blob);
});

const convertBase64ToBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const convertArrToObj = (array, objKey) => (!array ? {} : array.reduce(
  (obj, item) => ({ ...obj, [item[objKey]]: item }), {},
));

export const extractNameFromObj = (objList, field) => Object.values(objList).map(e => e[field]);

export const transformQueryResult = response => ({
  data: convertArrToObj(response.content, 'id'),
  meta: {
    pageSize: response.size || 20,
    currentPage: response.number || 0,
    totalCount: response.totalElements || 0,
    totalPages: response.totalPages || 0,
  },
});

const sendGetImageRequest = async (apiPath, authenticationToken) => {
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_GET;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });
  if (response.status >= 200 && response.status <= 299) {
    const blob = await response.blob();
    return blob.size === 0 ? undefined : convertBlobToBase64(blob);
  }
  const responseText = await response.text();

  throw new HttpError({ status: response.status, message: responseText });
};

const sendPostRequest = async (apiPath, body, authenticationToken) => {
  const bodyStr = JSON.stringify(body);
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_POST;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers, body: bodyStr });
  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }
  throw new HttpError({ status: response.status, message: responseText });
};

const getMimeType = (dataURI) => {
  const suffix = ';base64';
  const prefix = 'data:';
  const idx = dataURI.indexOf(suffix);

  if (idx > 0) {
    return dataURI.substring(0, idx).replace(prefix, '');
  }
  return '';
};

export const getDataType = (dataType) => {
  switch (dataType) {
    case Constant.TYPE_FILE:
      return Constant.type[0].value;
    case Constant.TYPE_NUMBER:
      return Constant.type[1].value;
    case Constant.TYPE_TEXT:
      return Constant.type[2].value;
    default:
      return Constant.type[3].value;
  }
};

const sendPostImageRequest = async (apiPath, image, authenticationToken) => {
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_POST;
  const headers = getHttpHeaders(authenticationToken);

  const mimeType = getMimeType(image);
  const blob = convertBase64ToBlob(image, mimeType);
  const body = new FormData();
  body.append('file', blob);

  const request = {
    method,
    headers: _.omit(headers, 'Content-Type'),
    body,
  };

  const response = await fetch(url, request);

  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }
  throw new HttpError({ status: response.status, message: responseText });
};

const sendDeleteRequest = async (apiPath, authenticationToken) => {
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_DELETE;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });

  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }
  throw new HttpError({ status: response.status, message: responseText });
};

export const sendPutRequest = async (apiPath, body, authenticationToken) => {
  const bodyStr = JSON.stringify(body);
  const url = `${Constant.REST_BASE_URL}${apiPath}`;
  const method = Constant.REST_METHOD_PUT;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers, body: bodyStr });

  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }
  throw new HttpError({ status: response.status, message: responseText });
};

export const saveImageToFile = async (base64Image) => {
  const id = uuid();
  const imageStr = `${Constant.IMAGE_SOURCE_URI_PREFIX}${base64Image}`;
  localStorage.setItem(id, imageStr);
  return { id, content: imageStr };
};

export const getImageFromStorage = imageId => localStorage.getItem(imageId);

const downloadImage = async (url, token) => {
  const image = await sendGetImageRequest(url, token);
  if (image) {
    const img = await saveImageToFile(image);
    return img;
  }
  return undefined;
};

export const removeAllStorage = async () => {
  const keys = _.keys(localStorage);

  keys.forEach((x) => {
    try {
      localStorage.removeItem(x);
    } catch (error) {
      // ignore errors
    }
  });
};

export const readFile = file => new Promise((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve(reader.result), false);
  reader.readAsDataURL(file);
});

export const registerEmail = (email) => {
  const body = { email };
  return sendPostRequest(Constant.REST_URL_REGISTER, body);
};

export const register = (registrationInfo) => {
  const body = { ...registrationInfo };
  return sendPostRequest(Constant.REST_URL_ACTIVATE_REGISTRATION, body);
};

export const forgetPassword = (email) => {
  const body = { email };
  return sendPostRequest(Constant.REST_URL_FORGET_PASSWORD, body);
};

export const resetPassword = (email, pin, newPassword) => {
  const body = { email, pin, newPassword };
  return sendPostRequest(Constant.REST_URL_RESET_PASSWORD, body);
};

export const login = async (username, password) => {
  const body = { username, password };
  const auth = await sendPostRequest(Constant.REST_URL_LOGIN, body);
  return auth;
};

export const logout = async token => sendPostRequest(Constant.REST_URL_LOGOUT, null, token);

export const downloadMyProfile = async (token) => {
  const profile = await sendGetRequest(Constant.REST_URL_MYPROFILE, token);
  return profile;
};

export const downloadDepositBalance = async (token) => {
  const profile = await sendPostRequest(Constant.REST_URL_DEPOSIT_REFRESH, null, token);
  return profile;
};

export const removeProfilePictureFromStorage = async (userId, state) => {
  const user = state.users[userId];
  if (user) {
    const img = state.images[user.profilePicture];
    if (img) {
      localStorage.removeItem(img.id);
    }
  }
};

export const downloadProfilePicture = async (profileId, token) => {
  const url = Constant.REST_URL_PROFILE_PICTURE.replace(/\{id\}/, profileId);
  const img = await downloadImage(url, token);
  return img;
};

export const uploadProfilePicture = async (
  newProfilePict,
  profileId,
  token,
) => {
  const body = newProfilePict;
  const url = Constant.REST_URL_PROFILE_PICTURE.replace(/\{id\}/, profileId);
  await sendPostImageRequest(url, body, token);
};

export const uploadProfileDetails = async (newProfileDetails, token) => {
  const body = newProfileDetails;
  const url = Constant.REST_URL_MYPROFILE;
  await sendPostRequest(url, body, token);
};

export const updatePassword = async (oldPassword, newPassword, token) => {
  const body = { oldPassword, newPassword };
  const url = Constant.REST_URL_CHANGE_PASSWORD;
  await sendPostRequest(url, body, token);
};

export const updatePin = async (oldPin, newPin, token) => {
  const body = { oldPin, newPin };
  const url = Constant.REST_URL_CHANGE_PIN;
  await sendPostRequest(url, body, token);
};

export const deleteProfilePicture = async (profileId, token) => {
  const url = Constant.REST_URL_PROFILE_PICTURE.replace(/\{id\}/, profileId);
  await sendDeleteRequest(url, token);
};

export const downloadAllProfiles = (token, searchString = '') => {
  const url = searchString
    ? `${Constant.REST_URL_PROFILE}/search?searchString=${searchString}`
    : Constant.REST_URL_PROFILE;
  return sendGetRequest(url, token);
};

export const downloadAllForwarders = (token) => {
  const url = Constant.REST_URL_FORWARDER;
  return sendGetRequest(url, token);
};

export const downloadAllVendor = async (token) => {
  const url = Constant.REST_URL_VENDOR.replace(/\{name\}/, '');
  const vendors = await sendGetRequest(url, token);
  const vendorObj = convertArrToObj(
    vendors, Constant.OBJ_KEY_VENDOR,
  );
  return vendorObj;
};

export const downloadExportOrderHistory = async (token) => {
  const exportOrderHistory = await sendGetRequest(
    Constant.REST_URL_EXPORT, token,
  );
  const exportOrderHistoryObj = convertArrToObj(
    exportOrderHistory, Constant.OBJ_KEY_EXPORT,
  );
  return exportOrderHistoryObj;
};

export const downloadExportOrderHistoryDetail = async (coRef, token) => {
  const url = Constant.REST_URL_EXPORT_ORDER_HISTORY_DETAIL.replace(/\{coRef\}/, coRef);
  const orderDetail = await sendGetRequest(url, token);
  const orderDetailObj = convertArrToObj(
    orderDetail, Constant.OBJ_KEY_EXPORT_DETAIL,
  );
  return orderDetailObj;
};

export const downloadExportPriceDetail = async (blNo, paymentOnBehalf, token) => {
  const url = Constant.REST_URL_SERVICE_PRICE_DETAIL
    .replace(/\{blNo\}/, blNo)
    .replace(/\{service\}/, Constant.CART_SERVICE_NAME_EXPORT)
    .replace(/\{pob\}/, paymentOnBehalf)
    .replace(/\{etd\}/, '');
  const priceDetail = await sendGetRequest(url, token);
  return priceDetail;
};

export const downloadFeederServicePriceDetail = async (refNo, paymentOnBehalf, token) => {
  const url = Constant.REST_URL_SERVICE_PRICE_DETAIL
    .replace(/\{blNo\}/, refNo)
    .replace(/\{service\}/, Constant.CART_SERVICE_NAME_TRUCKING)
    .replace(/\{pob\}/, paymentOnBehalf)
    .replace(/\{etd\}/, '');
  const priceDetail = await sendGetRequest(url, token);
  return priceDetail;
};

export const downloadMyPaymentTermOrder = async (token) => {
  const url = Constant.REST_URL_MY_PAYMENT_TERM;
  const myTermOrder = await sendGetRequest(url, token);
  const myTermOrderObj = convertArrToObj(
    myTermOrder, Constant.OBJ_KEY_MY_PAYMENT_TERM,
  );
  return myTermOrderObj;
};


export const downloadFeederServiceBLList = async (token) => {
  const url = Constant.REST_URL_FEEDER_SERVICE_BL_LIST;
  const blList = await sendGetRequest(url, token);
  const blListObj = convertArrToObj(
    blList, Constant.OBJ_KEY_EXPORT,
  );
  return blListObj;
};

export const downloadFeederServiceDisclaimer = async (token) => {
  const disclaimer = await sendGetRequest(Constant.REST_URL_FEEDER_SERVICE_DISCLAIMER, token);
  return disclaimer;
};


export const uploadTermOrderItem = async (termOrderItem, token, password) => {
  const body = { ...termOrderItem, password };
  return sendPostRequest(Constant.REST_URL_MY_TERM_ORDER, body, token);
};

export const uploadAdminPayment = (paymentData, token) => sendPostRequest(Constant.REST_URL_CREATE_ADMIN_PAYMENT, paymentData, token);

export const downloadVesselList = async (name, token) => {
  const url = Constant.REST_URL_VESSEL_BY_NAME.replace(/\{name\}/, name);
  const vesselList = await sendGetRequest(url, token);
  return vesselList;
};

export const downloadVoyageList = async (vessel, name, token) => {
  const url = Constant.REST_URL_VOYAGE_BY_NAME
    .replace(/\{vessel\}/, vessel)
    .replace(/\{name\}/, name);
  const voyageList = await sendGetRequest(url, token);
  return voyageList;
};

export const downloadVoyageDetail = async (vesselName, voyageName, token) => {
  const addVessel = Constant.REST_URL_VOYAGE_DETAIL.replace(/\{vesselName\}/, vesselName);
  const url = addVessel.replace(/\{voyageName\}/, voyageName);
  const voyageDetail = await sendGetRequest(url, token);
  return voyageDetail;
};

export const bookFeederService = (bookFeeederInfo, token) => {
  const body = { ...bookFeeederInfo };
  const bookNo = sendPostRequest(Constant.REST_URL_BOOK_FEEDER, body, token);
  return bookNo;
};

export const bookExportService = (bookExportInfo, token) => {
  const body = { ...bookExportInfo };
  const bookNo = sendPostRequest(Constant.REST_URL_EXPORT, body, token);
  return bookNo;
};

export const submitOrder = (password, paymentChannelCode, orderItems, token) => {
  const body = {
    password,
    paymentChannelCode,
    items: orderItems,
  };
  return sendPostRequest(Constant.REST_URL_SUBMIT_ORDER, body, token);
};

export const downloadContainerTrackings = async (blNo, token) => {
  const url = Constant.REST_URL_CONTAINER_TRACKING;
  const containerData = await sendGetRequest(url, token);
  const containerDataObj = convertArrToObj(containerData, Constant.OBJ_KEY_CONTAINER_TRACKING);
  return containerDataObj;
};
export const downloadContainerTrackingDetail = async (blNo, token) => {
  const url = Constant.REST_URL_CONTAINER_TRACKING_DETAIL.replace(/\{blNo\}/, blNo);
  const containerData = await sendGetRequest(url, token);
  const containerDataObj = convertArrToObj(containerData,
    Constant.OBJ_KEY_CONTAINER_TRACKING_DETAIL);
  return containerDataObj;
};

export const downloadReeferData = async (token) => {
  const reeferData = await sendGetRequest(Constant.REST_URL_REEFER_DATA, token);
  const referDataObj = convertArrToObj(reeferData, Constant.OBJ_KEY_REEFER_DATA);
  return referDataObj;
};

export const downloadReeferDataDetails = async (blNo, containerNo, token) => {
  const replaceBlNo = Constant.REST_URL_REEFER_DATA_DETAIL.replace(/\{blNo\}/, blNo);
  const url = replaceBlNo.replace(/\{containerNo\}/, containerNo);
  const reeferDataDetails = await sendGetRequest(url, token);
  const reeferDataDetailsObj = convertArrToObj(
    reeferDataDetails,
    Constant.OBJ_KEY_REEFER_DATA_DETAILS,
  );
  return reeferDataDetailsObj;
};

export const downloadTariffSimulation = async (blNo, etd, pob, token) => {
  const url = Constant.REST_URL_TARIFF_SIMULATION
    .replace(/\{blNo\}/, blNo)
    .replace(/\{etd\}/, etd)
    .replace(/\{pob\}/, pob);
  const tariffSimulation = await sendGetRequest(url, token);
  return tariffSimulation;
};

export const downloadFeederServiceOrderHistories = async (token) => {
  const feederServiceOrderHistories = await sendGetRequest(
    Constant.REST_URL_FEEDER_SERVICE_ORDER_HISTORY, token,
  );
  const feederServiceOrderHistoriesObj = convertArrToObj(
    feederServiceOrderHistories, Constant.OBJ_KEY_FEEDER_SERVICE_ORDER_HISTORIES,
  );
  return feederServiceOrderHistoriesObj;
};

export const downloadFeederServiceOrderDetail = async (blNo, token) => {
  const url = Constant.REST_URL_FEEDER_SERVICE_ORDER_DETAIL.replace(/\{blNo\}/, blNo);
  const orderDetail = await sendGetRequest(url, token);
  const orderDetailObj = convertArrToObj(
    orderDetail, Constant.OBJ_KEY_FEEDER_SERVICE_ORDER_DETAILS,
  );
  return orderDetailObj;
};

export const downloadPpjkCompanyList = async (custId, token) => {
  const url = Constant.REST_URL_PPJK_COMPANY_LIST.replace(/\{custId\}/, custId);
  const ppjkCompanyList = await sendGetRequest(url, token);
  const ppjkCompanyListObj = convertArrToObj(
    ppjkCompanyList, Constant.OBJ_KEY_PPJK_COMPANY_LIST,
  );
  return ppjkCompanyListObj;
};

export const downloadCustomerCompanyList = async (ppjkId, token) => {
  const url = Constant.REST_URL_CUSTOMER_COMPANY_LIST.replace(/\{ppjkId\}/, ppjkId);
  const customerCompanyList = await sendGetRequest(url, token);
  const customerCompanyListObj = convertArrToObj(
    customerCompanyList, Constant.OBJ_KEY_CUSTOMER_COMPANY_LIST,
  );
  return customerCompanyListObj;
};

export const downloadFeederServiceForwarderList = async (token) => {
  const forwarderList = await sendGetRequest(
    Constant.REST_URL_FEEDER_SERVICE_FORWARDER_LIST, token,
  );
  const forwarderListObj = convertArrToObj(
    forwarderList, Constant.OBJ_KEY_FEEDER_SERVICE_FORWARDER_LIST,
  );
  return forwarderListObj;
};

export const downloadFeederServiceEmptyDepoList = async (token) => {
  const emptyDepoList = await sendGetRequest(
    Constant.REST_URL_FEEDER_SERVICE_EMPTY_DEPO_LIST, token,
  );
  const emptyDepoListObj = convertArrToObj(
    emptyDepoList, Constant.OBJ_KEY_FEEDER_SERVICE_EMPTY_DEPO_LIST,
  );
  return emptyDepoListObj;
};

export const downloadFeederServiceAreaList = async (exim, token) => {
  const areaList = await sendGetRequest(
    Constant.REST_URL_FEEDER_SERVICE_AREA_LIST.replace(/\{exim\}/, exim), token,
  );
  const areaListObj = convertArrToObj(
    areaList, Constant.OBJ_KEY_FEEDER_SERVICE_AREA_LIST,
  );
  return areaListObj;
};

export const downloadFeederServiceWarehouseList = async (billTo, token) => {
  const warehouseList = await sendGetRequest(
    Constant.REST_URL_FEEDER_SERVICE_WAREHOUSE_LIST.replace(/\{key\}/, billTo), token,
  );
  const warehouseListObj = convertArrToObj(
    warehouseList, Constant.OBJ_KEY_FEEDER_SERVICE_WAREHOUSE_LIST,
  );
  return warehouseListObj;
};

export const downloadFeederServiceBookPartyList = async (bookNo, token) => {
  const url = Constant.REST_URL_FEEDER_SERVICE_BOOK_PARTY_LIST.replace(/\{bookNo\}/, bookNo);
  const bookPartyList = await sendGetRequest(
    url, token,
  );

  const newBookPartyList = bookPartyList.map(item => ({ ...item, deleted: false, guid: uuid() }));

  const bookPartyListObj = convertArrToObj(
    newBookPartyList, Constant.OBJ_KEY_FEEDER_SERVICE_BOOK_PARTY_LIST,
  );
  return bookPartyListObj;
};

export const downloadTariffSimulationOrder = async (token) => {
  const url = Constant.REST_URL_TARIFF_SIMULATION_ORDER;
  const tariffSimulationOrder = await sendGetRequest(url, token);
  const tariffSimulationOrderObj = convertArrToObj(
    tariffSimulationOrder, Constant.OBJ_KEY_TARIFF_SIMULATION_ORDER,
  );
  return tariffSimulationOrderObj;
};

export const downloadMyPaymentOrder = async (token) => {
  const url = Constant.REST_URL_MY_PAYMENT_ORDER;
  const myOrder = await sendGetRequest(url, token);

  const transformedMyOrder = myOrder.map(order => ({
    ...order,
    items: order.items.map(i => ({
      ...i,
      service: i.service === Constant.SERVICE_TYPE_PREDEFINED_ORDER ? i.originalService : i.service,
    })),
  }));

  const myOrderObj = convertArrToObj(
    transformedMyOrder, Constant.OBJ_KEY_MY_PAYMENT_ORDER,
  );
  return myOrderObj;
};


export const downloadMyOutstandingPayment = async (token) => {
  const url = Constant.REST_URL_MY_OUTSTANDING_PAYMENT;

  const response = await sendGetRequest(url, token);

  const myOutstandingOrder = response.map(x => ({
    ...x,
    outstandingType: Constant.PAYMENT_TYPE_CASH,
  }));

  const myOutstandingOrderObj = convertArrToObj(
    myOutstandingOrder, Constant.OBJ_KEY_MY_OUTSTANDING_PAYMENT,
  );
  return myOutstandingOrderObj;
};

export const downloadMyOutstandingTermPayment = async (token) => {
  const url = Constant.REST_URL_MY_OUTSTANDING_TERM_PAYMENT;
  const response = await sendGetRequest(url, token);

  const myOutstandingTermOrder = response.map(x => ({
    ...x,
    outstandingType: Constant.PAYMENT_TYPE_TERM,
  }));

  const myOutstandingTermOrderObj = convertArrToObj(
    myOutstandingTermOrder, Constant.OBJ_KEY_MY_OUTSTANDING_TERM_PAYMENT,
  );
  return myOutstandingTermOrderObj;
};

export const downloadAssign = async (token) => {
  const url = Constant.REST_URL_ASSIGN;
  const assign = await sendGetRequest(url, token);
  const assignObj = convertArrToObj(
    assign, Constant.OBJ_KEY_ASSIGN,
  );
  return assignObj;
};

export const downloadAssignOrderType = async (token, blNo) => {
  const url = Constant.REST_URL_ASSIGN_ORDER_TYPE.replace(/\{blNo\}/, blNo);
  const assignOrderType = await sendGetRequest(url, token);
  const assignOrderTypeObj = convertArrToObj(
    assignOrderType, Constant.OBJ_KEY_ASSIGN_ORDER_TYPE,
  );
  return assignOrderTypeObj;
};

export const downloadAssignContainer = async (token, blNo, soNo) => {
  const addBlNo = Constant.REST_URL_ASSIGN_CONTAINER.replace(/\{blNo\}/, blNo);
  const url = addBlNo.replace(/\{soNo\}/, soNo);
  const assignContainer = await sendGetRequest(url, token);
  const assignContainerObj = convertArrToObj(
    assignContainer, Constant.OBJ_KEY_ASSIGN_CONTAINER,
  );
  return assignContainerObj;
};


export const downloadDoList = async (token) => {
  const url = Constant.REST_URL_DO_LIST;
  const doList = await sendGetRequest(url, token);
  const doListObj = convertArrToObj(
    doList, Constant.OBJ_KEY_DO_LIST,
  );
  return doListObj;
};

export const downloadPpjkListByAssign = async (name, token) => {
  const url = Constant.REST_URL_PPJK_LIST_BY_ASSIGN.replace(/\{name\}/, name || '');
  const ppjkList = await sendGetRequest(url, token);
  const ppjkListObj = convertArrToObj(
    ppjkList, Constant.OBJ_KEY_PPJK_LIST_BY_ASSIGN,
  );
  return ppjkListObj;
};

export const downloadTruckList = async (token, vendorId, text) => {
  const url = Constant.REST_URL_TRUCK_LIST
    .replace(/\{vendorId\}/, vendorId)
    .replace(/\{truckNo\}/, text || '');
  const truckList = await sendGetRequest(url, token);
  const truckListObj = convertArrToObj(
    truckList, Constant.OBJ_KEY_TRUCK_LIST,
  );
  return truckListObj;
};

export const downloadMyNotifications = async (token) => {
  const url = Constant.REST_URL_MY_NOTIFICATION;
  const notificationList = await sendGetRequest(url, token);
  const notificationListObj = convertArrToObj(
    notificationList, Constant.OBJ_KEY_NOTIFICATION_LIST,
  );
  return notificationListObj;
};

export const downloadDepositHistory = async (token, filters) => {
  const url = Constant.REST_URL_DEPOSIT_HISTORY
    .replace(/{startDate}/, filters.startDate)
    .replace(/{endDate}/, filters.endDate);
  const depositHistory = await sendGetRequest(url, token);
  const depositHistoryObj = convertArrToObj(
    depositHistory, Constant.OBJ_KEY_DEPOSIT_HISTORY,
  );
  return depositHistoryObj;
};

export const downloadExportDGClassList = async (token) => {
  const dgClasses = await sendGetRequest(Constant.REST_URL_DG_CLASS_LIST, token);
  const dgClassObj = convertArrToObj(
    dgClasses, Constant.OBJ_KEY_EXPORT_DG_CLASS,
  );
  return dgClassObj;
};

export const downloadDepositHistoryCsv = (token, filters) => {
  const url = Constant.REST_URL_DEPOSIT_HISTORY_CSV_REQ
    .replace(/{startDate}/, filters.startDate)
    .replace(/{endDate}/, filters.endDate);
  return sendGetRequest(url, token);
};

export const downloadMoneyLoanHistoryCsv = token => sendGetRequest(Constant.REST_URL_MONEY_LOAN_HISTORY_REQUEST_DOWNLOAD_CSV, token);

export const downloadMyPaymentHistoryCsv = token => sendGetRequest(Constant.REST_URL_MY_PAYMENT_ORDER_CSV_REQ, token);

export const downloadAdminPaymentHistoryCsv = token => sendGetRequest(Constant.REST_URL_ADMIN_PAYMENT_HISTORY_CSV_REQ, token);

export const downloadAdminPaymentHistory = async (pageNumber, pageSize, sortField, sortDirection, searchText, token) => {
  const url = Constant.REST_URL_ADMIN_PAYMENT_HISTORY
    .replace(/{pageNumber}/, pageNumber)
    .replace(/{pageSize}/, pageSize)
    .replace(/{sortField}/, sortField)
    .replace(/{sortDirection}/, sortDirection)
    .replace(/{searchText}/, searchText);
  const histories = await sendGetRequest(url, token);
  const contentObj = convertArrToObj(
    histories.content, Constant.OBJ_KEY_ADMIN_PAYMENT_HISTORY,
  );
  return { ...histories, contentObj };
};

export const downloadAdminPayableBl = async (searchTerm, token) => {
  const url = Constant.REST_URL_ADMIN_PAYABLE_BL.replace(/{key}/, searchTerm);
  const result = await sendGetRequest(url, token);

  return convertArrToObj(result, Constant.OBJ_KEY_ADMIN_PAYABLE_BL);
};

export const downloadAdminPayableBlPaymentOwner = async (companyId, token) => {
  const url = Constant.REST_URL_ADMIN_PAYABLE_BL_PAYMENT_OWNER.replace(/{key}/, companyId);
  const result = await sendGetRequest(url, token);

  return convertArrToObj(result, Constant.OBJ_KEY_ADMIN_PAYABLE_BL_PAYMENT_OWNER);
};

export const requestPrint = (data, token) => {
  const url = Constant.REST_URL_PRINT_REQUEST;
  return sendPostRequest(url, data, token);
};


export const uploadAssignClearance = async (data, token) => {
  const url = Constant.REST_URL_SUBMIT_ASSIGN_CLEARANCE;
  await sendPostRequest(url, data, token);
};

export const uploadAssignContainer = async (data, token) => {
  const url = Constant.REST_URL_SUBMIT_ASSIGN_CONTAINER;
  await sendPostRequest(url, data, token);
};

export const uploadBookPartyDocument = (bookPartyDocument, token) => {
  const body = { ...bookPartyDocument };
  const bookFeederPriceContent = sendPostRequest(
    Constant.REST_URL_BOOK_PARTY_DOCUMENT,
    body,
    token,
  );
  return bookFeederPriceContent;
};

export const addUser = (newProfileInfo, token) => {
  const url = Constant.REST_URL_PROFILE;
  const body = newProfileInfo;
  return sendPostRequest(url, body, token);
};

export const updateUser = (newProfileInfo, token) => {
  const url = Constant.REST_URL_PROFILE;
  const body = newProfileInfo;
  return sendPostRequest(url, body, token);
};

export const uploadContactUsMessage = (values, token) => {
  const url = Constant.REST_URL_CONTACT_US;
  const body = values;
  return sendPostRequest(url, body, token);
};

export const uploadShoppingCartItem = async (cartItem, token) => {
  const url = Constant.REST_URL_SHOPPING_CART;
  await sendPostRequest(url, cartItem, token);
};

export const uploadNewUser = (userInfo, token) => {
  const url = Constant.REST_URL_PROFILE;
  const body = userInfo;
  return sendPostRequest(url, body, token);
};

export const uploadUpdateUser = (userId, userInfo, token) => {
  const url = Constant.REST_URL_UPDATE_PROFILE.replace(/\{id\}/, userId);
  const body = userInfo;
  return sendPostRequest(url, body, token);
};

export const downloadAllForm = async (token, pageNumber, pageSize) => {
  const url = `${Constant.REST_URL_FORM}?page=${pageNumber}&size=${pageSize}`;
  const forms = await sendGetRequest(url, token);
  const transformedForms = {
    ...forms,
    content: forms.content.map(x => ({
      ...x,
      title: x.title || '',
      status: x.status || '',
      description: x.description || '',
    })),
  };
  const formObj = transformQueryResult(
    transformedForms, Constant.OBJ_KEY_FORM,
  );
  return formObj;
};

export const downloadFormDetail = async (token, id) => {
  const url = Constant.REST_URL_FORM_DETAIL
    .replace(/\{id\}/, id);
  const forms = await sendGetRequest(url, token);
  return forms;
};

export const uploadNewForm = async (formInfo, token) => {
  const url = Constant.REST_URL_FORM;
  const body = formInfo;
  await sendPostRequest(url, body, token);
};

export const uploadUpdateForm = async (formId, formInfo, token) => {
  const url = Constant.REST_URL_FORM;
  const body = formInfo;
  await sendPutRequest(url, body, token);
};

export const deleteForm = async (formId, token) => {
  const url = Constant.REST_URL_FORM_DETAIL.replace(/\{id\}/, formId);
  await sendDeleteRequest(url, token);
};

export const downloadAllField = async (token, formId, pageNumber, pageSize) => {
  const url = formId !== ''
    ? `${Constant.REST_URL_FIELD}?formId=${formId}&page=${pageNumber}&size=${pageSize}`
    : `${Constant.REST_URL_FIELD}?page=${pageNumber}&size=${pageSize}`;
  const fields = await sendGetRequest(url, token);
  const formObj = transformQueryResult(
    fields, Constant.OBJ_KEY_FIELD,
  );
  return formObj;
};

export const downloadFieldDetail = async (token, id) => {
  const url = Constant.REST_URL_FIELD_DETAIL
    .replace(/\{id\}/, id);
  const forms = await sendGetRequest(url, token);
  return forms;
};

export const uploadNewField = async (fieldInfo, token) => {
  const url = Constant.REST_URL_FIELD;
  const body = fieldInfo;
  await sendPostRequest(url, body, token);
};

export const uploadUpdateField = async (formId, fieldInfo, token) => {
  const url = Constant.REST_URL_FIELD;
  const body = fieldInfo;
  await sendPutRequest(url, body, token);
};

export const deleteField = async (formId, token) => {
  const url = Constant.REST_URL_FIELD_DETAIL.replace(/\{id\}/, formId);
  await sendDeleteRequest(url, token);
};

export const uploadReadNotification = async (readNotificationDetail, token) => {
  const body = readNotificationDetail;
  const url = Constant.REST_URL_MY_NOTIFICATION;
  await sendPostRequest(url, body, token);
};

export const uploadAssignContainerAdd = async (data, token) => {
  const url = Constant.REST_URL_ASSIGN_ADD_CONTAINER;
  await sendPostRequest(url, data, token);
};

export const uploadAssignTruckExport = async (data, token) => {
  const url = Constant.REST_URL_ASSIGN_TRUCK_EXPORT;
  await sendPostRequest(url, data, token);
};

export const uploadRegisterInitialPassword = async (data) => {
  const url = Constant.REST_URL_REGISTER_PASSWORD;
  const response = await sendPostRequest(url, data);
  return response;
};

export const renderReduxFormTextField = ({
  input,
  label,
  type,
  required,
  disabled,
  className,
  meta: { touched, error },
  endAdornment,
  startAdornment,
  readonly,
  autofocus,
  inputClassName,
  inputStyle,
  multiline,
}) => (
  <div>
    <FormControl
      margin="normal"
      {...input}
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      {label && <InputLabel htmlFor="component-error">{label}</InputLabel>}
      <Input
        type={type}
        {...input}
        readOnly={readonly}
        endAdornment={endAdornment}
        startAdornment={startAdornment}
        autoFocus={autofocus}
        style={inputStyle}
        multiline={multiline}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  </div>
);

export const renderReduxFormBigDateField = ({
  input,
  label,
  className,
  required,
  disabled,
  meta: { touched, error },
  format,
  endAdornment,
  value,
  keyboard,
  ...props
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <DatePicker
        {...input}
        keyboard={keyboard}
        value={input.value || null}
        disabled={disabled}
        required={required}
        format={format || Constant.MOMENT_DATE_FORMAT_STRING}
        label={label}
        error={touched && !!error}
        placeholder={format || '01 Jan 2019'}
        mask={(dateValue) => {
          if (dateValue) {
            return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
          }
          return [];
        }}
        readOnly
        clearable
        endAdornment={endAdornment}
      />
    </FormControl>
  </div>
);

export const renderReduxFormDateField = ({
  input,
  label,
  className,
  required,
  disabled,
  meta: { touched, error },
  format,
  value,
  keyboard,
  ...props
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <InlineDatePicker
        {...input}
        onBlur={() => input.onBlur()}
        keyboard={keyboard}
        value={input.value || null}
        disabled={disabled}
        required={required}
        format={format || Constant.MOMENT_DATE_FORMAT_STRING}
        label={label}
        error={touched && !!error}
        placeholder={format || '01 Jan 2019'}
        mask={(dateValue) => {
          if (dateValue) {
            return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/];
          }
          return [];
        }}
        readOnly
        clearable
      />
    </FormControl>
  </div>
);


export const renderReduxFormDateTimeField = ({
  input,
  initialFocusedDate,
  label,
  className,
  required,
  disabled,
  meta: { touched, error },
  format,
  value,
  keyboard,
  ...props
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <DateTimePicker
        {...input}
        ampm={false}
        initialFocusedDate={initialFocusedDate}
        onBlur={() => input.onBlur()}
        keyboard={keyboard}
        value={input.value || null}
        disabled={disabled}
        required={required}
        format={format || Constant.MOMENT_DATE_TIME_FORMAT_STRING}
        // format={'DD MMM YYYY HH:mm'}
        label={label}
        error={touched && !!error}
        placeholder={format || '01 Jan 2019 08:00'}
        mask={(dateValue) => {
          if (dateValue) {
            return [/\d/, /\d/, ' ', /\w/, /\w/, /\w/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
          }
          return [];
        }}
        readOnly
        clearable
      />
    </FormControl>
  </div>
);


export const renderReduxFormTextArea = ({
  input,
  label,
  required,
  disabled,
  className,
  meta: { touched, error },
  readonly,
  rows,
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <TextField
        label={label}
        required={required}
        disabled={disabled}
        multiline
        rows={rows}
        {...input}
        readOnly={readonly}
        error={touched && !!error}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  </div>
);

export const renderReduxFormFilterableSelectField = ({
  input,
  label,
  type,
  required,
  disabled,
  className,
  meta: { touched, error },
  options,
  onValueChange,
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <FilterableSelect
        input={input}
        touched={touched}
        options={options}
        className={className}
        required={required}
        label={label}
        disabled={disabled}
        onValueChange={onValueChange}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  </div>
);

export const renderReduxFormFilterableSelectAsyncField = ({
  input,
  label,
  type,
  required,
  disabled,
  className,
  meta: { touched, error },
  options,
  onValueChange,
  onLazyLoad,
  disableDefaultOptions,
  onTextChange,
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <FilterableSelectAsync
        input={input}
        touched={touched}
        options={options}
        className={className}
        required={required}
        label={label}
        disabled={disabled}
        onValueChange={onValueChange}
        onLazyLoad={onLazyLoad}
        disableDefaultOptions={disableDefaultOptions}
        onTextChange={onTextChange}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  </div>
);

export const renderReduxFormFilterableSelectCreateAsyncField = ({
  input,
  label,
  type,
  required,
  disabled,
  className,
  meta: { touched, error },
  options,
  onValueChange,
  disableDefaultOptions,
}) => (
  <div>
    <FormControl
      margin="normal"
      required={required}
      disabled={disabled}
      className={className}
      error={touched && !!error}
    >
      <FilterableSelectCreateAsync
        input={input}
        touched={touched}
        options={options}
        className={className}
        required={required}
        label={label}
        disabled={disabled}
        onValueChange={onValueChange}
        disableDefaultOptions={disableDefaultOptions}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  </div>
);

export const renderReduxFormFilePickerField = (props) => {
  const {
    input: { value, ...restInput },
    meta: { touched, error },
    required,
    onFileSelected,
    label,
    helperText,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <FilePickerField
      error={!!errorProps}
      helperText={helperText || errorProps}
      onFileSelected={onFileSelected}
      label={labelProps}
      value={value}
      required={required}
      {...restInput}
      {...restProps}
    />
  );
};

export const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox label={label} checked={input.value} onChange={input.onChange} />
    }
  />
);

// Table Related Helper
const UnsortedArrow = () => (
  <div style={{ display: 'flex', flexDirection: 'column', color: 'silver' }}>
    <Icon style={{ marginBottom: '-16px' }}>arrow_drop_up</Icon>
    <Icon>arrow_drop_down</Icon>
  </div>
);

const AscendingArrow = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Icon style={{ marginBottom: '-16px' }}>arrow_drop_up</Icon>
    <Icon style={{ color: 'silver' }}>arrow_drop_down</Icon>
  </div>
);

const DescendingArrow = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Icon style={{ marginBottom: '-16px', color: 'silver' }}>arrow_drop_up</Icon>
    <Icon>arrow_drop_down</Icon>
  </div>
);

export const isSortFieldActive = (sortField, tableField) => (sortField === tableField);
export const getSortIcon = (isActive, sortDirection) => {
  if (!isActive) {
    return UnsortedArrow;
  }
  return sortDirection === Constant.TABLE_SORT_ASCENDING ? AscendingArrow : DescendingArrow;
};

export const toNumber = (text, isPrice = false) => (isPrice ? parseInt(text.toString().replace(/\./g, ''), 10) : parseInt(text, 10));

export const toCurrency = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return '';
};

export const toMoment = dateTime => moment(dateTime)
  .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

export const renderErrorMessage = msg => (
  <Typography style={{ color: '#f00', marginTop: '24px' }}>{msg}</Typography>
);

export const unauthorizedErrorHandler = (error, navigateTo) => {
  if (error.status === 401 && error.message === LocalizedString.common.sessionExpired) {
    navigateTo(Constant.ROUTE_NAME_LOGIN);
  }
};

export const openNewTab = url => window.open(url);

export const cancelOrder = async (token, body) => {
  const url = Constant.REST_URL_CANCEL_ORDER;
  const cancelledItems = await sendPostRequest(url, body, token);
  return cancelledItems;
};

export const changeOrderPayment = (staticVa, password, proformaNo, paymentChannelCode, token) => {
  let body = {};
  if (!staticVa) {
    body = {
      password,
      proformaNo,
    };
  } else {
    body = {
      password,
      proformaNo,
      paymentChannelCode,
    };
  }

  return sendPostRequest(Constant.REST_URL_CHANGE_ORDER_PAYMENT, body, token);
};


export const downloadProformaInvoicePrintId = async (token, invoiceNo, isPredefinedOrder) => {
  const url = Constant.REST_URL_PRINT_REQUEST;
  const body = {
    invoiceNo,
    blNo: '',
    service: '',
    printType: isPredefinedOrder
      ? Constant.PRINT_TYPE_PREDEFINED_PROFORMA_INVOICE
      : Constant.PRINT_TYPE_PROFORMA_INVOICE,
  };
  const response = await sendPostRequest(url, body, token);
  return response.printId;
};


export const downloadTariffSimulationUnassignedOrder = async (blNo, token) => {
  const url = Constant.REST_URL_UNASSIGNED_TARIFF_SIMULATION_ORDER.replace(/\{blNo\}/, blNo);
  const response = await sendGetRequest(url, token);
  return convertArrToObj(response, Constant.OBJ_KEY_TARIFF_SIMULATION_ORDER);
  // return response;
};

export const downloadMoneyLoanInfo = async (token) => {
  const response = await sendGetRequest(Constant.REST_URL_MONEY_LOAN_INFO, token);
  return response;
};

export const downloadMoneyLoanHistory = async (token) => {
  const response = await sendGetRequest(Constant.REST_URL_MONEY_LOAN_HISTORY, token);
  return response;
};

export const downloadMoneyLoanTermsConditions = async (token) => {
  const url = `${Constant.REST_BASE_URL}${Constant.REST_URL_MONEY_LOAN_TERMS_CONDITIONS}`;
  const method = Constant.REST_METHOD_GET;
  const headers = await getHttpHeaders(token);
  const response = await fetch(url, { method, headers });
  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return responseText;
    }
    return undefined;
  }
  throw new Error(responseText);
};

export const submitMoneyLoan = async (pin, token) => {
  const body = { pin };
  await sendPostRequest(Constant.REST_URL_MONEY_LOAN_SUBMIT_LOAN, body, token);
};

export const downloadTrackingStatusBehandleHistories = async (token) => {
  const url = Constant.REST_URL_TRACKING_STATUS_BEHANDLE_HISTORIES;
  const data = await sendGetRequest(url, token);
  const result = convertArrToObj(
    data, Constant.OBJ_KEY_TRACKING_STATUS_BEHANDLE,
  );
  return result;
};

export const downloadTrackingStatusBehandleOnProgresses = async (token) => {
  const url = Constant.REST_URL_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES;
  const data = await sendGetRequest(url, token);
  const result = convertArrToObj(
    data, Constant.OBJ_KEY_TRACKING_STATUS_BEHANDLE,
  );
  return result;
};

export const downloadPredefinedOrder = async (token) => {
  const response = await sendGetRequest(Constant.REST_URL_PREDEFINED_ORDER, token);
  return convertArrToObj(response, 'blHeader');
};

export const downloadCheckoutTermAndConditionText = async (token) => {
  const url = `${Constant.REST_BASE_URL}${Constant.REST_URL_TERM_AND_CONDITION}`;
  const method = Constant.REST_METHOD_GET;
  const headers = await getHttpHeaders(token);
  const response = await fetch(url, { method, headers });
  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return responseText;
    }
    return undefined;
  }
  throw new Error(responseText);
};

export const downloadTariffSimulationHostBL = async (blNo, token) => {
  const url = Constant.REST_URL_TARIFF_SIMULATION_HOST_BL.replace(/\{blNo\}/, blNo);
  const data = await sendGetRequest(url, token);
  return data;
};

export const submitHostBl = async (blNo, masterBl, token) => {
  const body = {
    hostBl: blNo,
    masterBl,
  };
  const data = await sendPutRequest(
    Constant.REST_URL_TARIFF_SIMULATION_SUBMIT_HOST_BL, body, token,
  );
  return convertArrToObj(data, Constant.OBJ_KEY_TARIFF_SIMULATION_ORDER);
};

export const sortAsc = (a, b) => (a > b ? 1 : -1);
export const sortDesc = (a, b) => (a > b ? -1 : 1);

export const downloadFormResponses = async (
  token, selectedUser, selectedStatus, pageNumber, pageSize) => {
  const baseUrl = Constant.REST_URL_FORM_RESPONSES
    .replace(/\{status\}/, selectedStatus)
    .replace(/\{pageNumber\}/, pageNumber)
    .replace(/\{pageSize\}/, pageSize);
  const url = selectedUser ? `${baseUrl}&userId=${selectedUser}` : baseUrl;
  const data = await sendGetRequest(url, token);
  return data;
};

export const downloadFormResponseDetail = async (id, token) => {
  const url = Constant.REST_URL_FORM_RESPONSE_DETAIL
    .replace(/\{id\}/, id);
  const data = await sendGetRequest(url, token);
  return data;
};

export const downloadFormResponseFilterStatuses = async (token) => {
  const url = Constant.REST_URL_FORM_RESPONSE_FILTER_STATUSES;
  const data = await sendGetRequest(url, token);
  return data;
};

export const debounceSearch = func => _.debounce(func, Constant.DEBOUNCED_SEARCH_DURATION);

export const downloadDocSubmissionMyResponses = async (token) => {
  const url = Constant.REST_URL_DOC_SUBMISSION_MY_RESPONSES;
  const data = await sendGetRequest(url, token);
  const result = convertArrToObj(
    data, Constant.OBJ_KEY_DOC_SUBMISISON_MY_RESPONSE,
  );
  return result;
};

export const downloadDocSubmissionMyResponseDetail = async (id, token) => {
  const url = Constant.REST_URL_DOC_SUBMISSION_MY_RESPONSE_DETAIL.replace(/\{id\}/, id);
  const detail = await sendGetRequest(url, token);
  return detail;
};

export const downloadNewDocumentMyForms = async (token) => {
  const url = Constant.REST_URL_DOC_SUBMISSION_MY_FORMS;
  const data = await sendGetRequest(url, token);
  const result = convertArrToObj(
    data, Constant.OBJ_KEY_DOC_SUBMISISON_MY_RESPONSE,
  );
  return result;
};

export const downloadNewDocumentMyFormDetail = async (token, id) => {
  const url = Constant.REST_URL_DOC_SUBMISSION_MY_FORM_DETAIL
    .replace(/\{id\}/, id);
  const data = await sendGetRequest(url, token);
  return data;
};

export const uploadNewDocumentMyFormResponse = async (token, payload) => (
  sendPostRequest(Constant.REST_URL_DOC_SUBMISSION_MY_FORM_ADD_RESPONSE, payload, token)
);

export const downloadPaymentOnBehalfList = async (token) => {
  const url = Constant.REST_URL_PAYMENT_ON_BEHALF;
  const paymentOnBehalfList = await sendGetRequest(url, token);
  return paymentOnBehalfList;
};

export const downloadNewDocumentMyResponseFile = async (token, file) => {
  const url = Constant.REST_URL_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE
    .replace(/{file}/, file);
  const downloaded = await downloadImage(url, token);
  return downloaded;
};

export const downloadErpPaymentLogs = async (
  proformaNo = '', status = '', pageNumber = 1, pageSize = 20, token,
) => {
  const url = () => {
    let newUrl = Constant.REST_URL_ERP_PAYMENT_LOGS;

    if (proformaNo) newUrl += `&proformaNo=${proformaNo}`;
    if (status) newUrl += `&status=${status}`;

    return newUrl += `&page=${pageNumber}&size=${pageSize}`;
  };

  const data = await sendGetRequest(url(), token);
  const formObj = transformQueryResult(
    data, Constant.OBJ_KEY_ERP_PAYMENT_LOG,
  );
  return formObj;
};

export const downloadErpPaymentLogDetail = async (id, token) => {
  const url = Constant.REST_URL_ERP_PAYMENT_LOG_DETAIL
    .replace(/\{id\}/, id);
  const data = await sendGetRequest(url, token);
  return data;
};

export const retryErpPayment = async (id, token) => {
  const url = Constant.REST_URL_ERP_PAYMENT_LOG_RETRY
    .replace(/\{id\}/, id);
  const data = await sendPostRequest(url, {}, token);
  return data;
};

export const downloadInvoiceInfo = async (invoiceNo, token) => {
  const url = Constant.REST_URL_INVOICE_INFO.replace(/\{invoiceNo\}/, invoiceNo);
  const data = await sendGetRequest(url, token);
  return data;
};

export const isCgsItem = (item, invoiceInfo) => !!(item.service === Constant.SERVICE_TYPE_TRUCKING
  && invoiceInfo.emptyFakturNo);
