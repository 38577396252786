import * as Action from '../action';
import {
  TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO,
  TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT,
} from '../../constant';

const initialState = {
  downloading: false,
  searchBarText: '',
  tappedProformaNo: '',

  // print
  selectedInvoiceItem: null,
  infoDialogVisibility: false,
  requestingPrint: false,
  downloadingInvoiceInfo: false,
  requestingPrintId: '',
  requestingPrintType: '',

  // proforma
  filters: {
    [TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO]: '',
    [TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT]: '',
  },
  sortField: '',
  sortDirection: '',
  activePage: 1,
  pageSize: 5,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Action.REQUESTING_PRINT:
      return {
        ...state,
        requestingPrint: action.status,
        requestingPrintId: action.id,
        requestingPrintType: action.printType,
      };
    case Action.DOWNLOADING_MY_PAYMENT_ORDER:
      return { ...state, downloading: action.status };
    case Action.SET_MY_PAYMENT_HISTORY_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case Action.SET_TAPPED_PAYMENT_HISTORY_PROFORMA_NO:
      return { ...state, tappedProformaNo: action.tappedProformaNo };

    // proforma
    case Action.SET_MY_PAYMENT_HISTORY_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.value,
        },
      };
    }
    case Action.SET_MY_PAYMENT_HISTORY_PAGING: {
      return { ...state, ...action.value };
    }
    case Action.SET_MY_PAYMENT_HISTORY_SORT: {
      return { ...state, sortField: action.sortField, sortDirection: action.sortDirection };
    }
    case Action.SET_MY_PAYMENT_HISTORY_INFO_DIALOG_VISIBILITY:
      return {
        ...state,
        infoDialogVisibility: action.status,
        selectedInvoiceItem: action.data || null,
      };
    case Action.DOWNLOADING_INVOICE_INFO:
      return { ...state, downloadingInvoiceInfo: action.status };
    default: return state;
  }
};
