import { connect } from 'react-redux';
import InvoiceInfoDialog from './invoice-info-dialog.presentation';
import {
  requestPrintAsync, setMyPaymentHistoryInfoDialogVisibility, setPdfViewerStatus, showErrorSnackbar,
} from '../../redux/action';
import { PRINT_TYPE_INVOICE } from '../../constant';
import { unauthorizedErrorHandler } from '../../helper';
import LocalizedString from '../../localization';

const mapStateToProps = state => ({
  data: state.uiMyPaymentHistory.selectedInvoiceItem,
  visibility: state.uiMyPaymentHistory.infoDialogVisibility,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancelPressed: async () => {
    dispatch(setMyPaymentHistoryInfoDialogVisibility(false));
  },
  onOkPressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_INVOICE,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
      dispatch(setMyPaymentHistoryInfoDialogVisibility(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoDialog);
