import { CLEAR_INVOICE_INFO, SET_INVOICE_INFO } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_INVOICE_INFO:
      return action.data;
    case CLEAR_INVOICE_INFO:
      return {};
    default:
      return state;
  }
};
