import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, DialogTitle, Button, withStyles, Typography, IconButton,
  Icon,
} from '@material-ui/core';
import LocalizedString from '../../localization';
import { DEFAULT_STYLES } from '../../constant';

const styles = () => ({
  btnSubmit: {
    ...DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
  },
  centeredActions: {
    justifyContent: 'center',
    paddingBottom: '24px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
});

const InvoiceInfoDialog = ({
  classes,
  data,
  visibility,
  onCancelPressed,
  onOkPressed,
}) => (
  <Dialog
    open={visibility}
    fullWidth
    maxWidth="xs"
    classes={{
      paper: classes.dialogRoot,
    }}
  >
    <DialogTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography color="primary" variant="h6">
          {LocalizedString.common.alertTitleInfo}
        </Typography>
        <IconButton
          onClick={() => onCancelPressed()}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent className={classes.dialogContentRoot}>
      <Typography variant="body1">
        {LocalizedString.myPayment.msgInvoiceInfo}
      </Typography>
    </DialogContent>
    <DialogActions className={classes.centeredActions}>
      <Button
        color="primary"
        onClick={() => onCancelPressed()}
      >
        {LocalizedString.common.buttonCaptionCancel}
      </Button>
        &emsp;&emsp;
      <Button
        variant="contained"
        color="primary"
        className={classes.btnSubmit}
        onClick={() => onOkPressed(data)}
      >
        {LocalizedString.common.buttonCaptionOK}
      </Button>
    </DialogActions>
  </Dialog>
);

InvoiceInfoDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.any).isRequired,
  data: PropTypes.object.isRequired,
  visibility: PropTypes.bool.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onOkPressed: PropTypes.func.isRequired,
};

export default withStyles(styles)(InvoiceInfoDialog);
