import { validateBookExport } from '../../validation';

export const ADD_TOKEN = 'ADD_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_OUT = 'LOGGING_OUT';

export const CLEAR_ALL_STATES = 'CLEAR_ALL_STATES';

export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const ADD_PROFILE_PICTURE = 'ADD_PROFILE_PICTURE';
export const REMOVE_PROFILE_PICTURE = 'REMOVE_PROFILE_PICTURE';

export const ADD_CURRENT_USER = 'ADD_CURRENT_USER';

export const UPLOADING_PROFILE_DETAILS = 'UPLOADING_PROFILE_DETAILS';
export const UPLOADING_PROFILE_PICTURE = 'UPLOADING_PROFILE_PICTURE';
export const DOWNLOADING_PROFILE_PICTURE = 'DOWNLOADING_PROFILE_PICTURE';
export const REMOVING_PROFILE_PICTURE = 'REMOVING_PROFILE_PICTURE';
export const UPDATING_PASSWORD = 'UPDATING_PASSWORD';
export const UPDATING_PIN = 'UPDATING_PIN';

export const SET_PROFILE_SCREEN_EDIT_MODE = 'SET_PROFILE_SCREEN_EDIT_MODE';

export const ADD_IMAGE = 'ADD_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';

export const SUBMITTING_FORGET_PASSWORD = 'SUBMITTING_FORGET_PASSWORD';
export const SUBMITTING_RESET_PASSWORD = 'SUBMITTING_RESET_PASSWORD';
export const SUBMITTING_EMAIL_REGISTRATION = 'SUBMITTING_EMAIL_REGISTRATION';
export const SUBMITTING_REGISTRATION_INFO = 'SUBMITTING_REGISTRATION_INFO';

export const ADD_FORGET_PASSWORD_INFO = 'ADD_FORGET_PASSWORD_INFO';
export const REMOVE_FORGET_PASSWORD_INFO = 'REMOVE_FORGET_PASSWORD_INFO';

export const ADD_REGISTRATION_INFO = 'ADD_REGISTRATION_INFO';
export const REMOVE_REGISTRATION_INFO = 'REMOVE_REGISTRATION_INFO';

export const setUIError = (stateName, error) => ({
  type: `SET_ERROR_${stateName}`,
  error,
});

export const clearUIError = stateName => ({
  type: `CLEAR_ERROR_${stateName}`,
});

export const submittingRegistrationInfo = status => ({
  type: SUBMITTING_REGISTRATION_INFO,
  status,
});

export const addRegistrationInfo = info => ({
  type: ADD_REGISTRATION_INFO,
  info,
});

export const removeRegistrationInfo = () => ({
  type: REMOVE_REGISTRATION_INFO,
});

export const submittingEmailRegistration = status => ({
  type: SUBMITTING_EMAIL_REGISTRATION,
  status,
});

export const removeForgetPasswordInfo = () => ({
  type: REMOVE_FORGET_PASSWORD_INFO,
});

export const addForgetPasswordInfo = info => ({
  type: ADD_FORGET_PASSWORD_INFO,
  info,
});

export const submittingResetPassword = status => ({
  type: SUBMITTING_RESET_PASSWORD,
  status,
});

export const submittingForgetPassword = status => ({
  type: SUBMITTING_FORGET_PASSWORD,
  status,
});

export const addImage = image => ({
  type: ADD_IMAGE,
  image,
});

export const removeImage = id => ({
  type: REMOVE_IMAGE,
  id,
});

export const uploadingProfilePicture = status => ({
  type: UPLOADING_PROFILE_PICTURE,
  status,
});

export const uploadingProfileDetails = status => ({
  type: UPLOADING_PROFILE_DETAILS,
  status,
});

export const downloadingProfilePicture = status => ({
  type: DOWNLOADING_PROFILE_PICTURE,
  status,
});

export const removingProfilePicture = status => ({
  type: REMOVING_PROFILE_PICTURE,
  status,
});

export const updatingPassword = status => ({
  type: UPDATING_PASSWORD,
  status,
});

export const updatingPin = status => ({
  type: UPDATING_PIN,
  status,
});

export const setProfileScreenEditMode = status => ({
  type: SET_PROFILE_SCREEN_EDIT_MODE,
  status,
});

export const addCurrentUser = user => ({
  type: ADD_CURRENT_USER,
  user,
});

export const EDIT_CURRENT_USER = 'EDIT_CURRENT_USER';
export const editCurrentUser = user => ({
  type: EDIT_CURRENT_USER,
  user,
});

export const addUser = user => ({
  type: ADD_USER,
  user,
});

export const removeUser = id => ({
  type: REMOVE_USER,
  id,
});

export const addProfilePicture = (userId, imageId) => ({
  type: ADD_PROFILE_PICTURE,
  userId,
  imageId,
});

export const removeProfilePicture = userId => ({
  type: REMOVE_PROFILE_PICTURE,
  userId,
});

export const addToken = token => ({
  type: ADD_TOKEN,
  token,
});

export const removeToken = () => ({
  type: REMOVE_TOKEN,
});

export const loggingIn = status => ({ type: LOGGING_IN, status });
export const loggingOut = status => ({ type: LOGGING_OUT, status });

export const clearAllStates = () => ({
  type: CLEAR_ALL_STATES,
});

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const ADD_MENU = 'ADD_MENU';

export const toggleMenu = status => ({ type: TOGGLE_MENU, status });

export const ADD_FILTER = 'ADD_FILTER';
export const ADD_SORT = 'ADD_SORT';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const SET_PAGESIZE = 'SET_PAGESIZE';

export const addFilter = (rxState, field, value) => ({
  type: ADD_FILTER, rxState, field, value,
});
export const addSort = (rxState, field, direction) => ({
  type: ADD_SORT,
  rxState,
  field,
  direction,
});
export const setActivePage = (rxState, currentPage) => ({
  type: SET_ACTIVE_PAGE,
  rxState,
  currentPage,
});
export const setPageSize = (rxState, pageSize) => ({ type: SET_PAGESIZE, rxState, pageSize });

export const LOADING_FILTERED_USER_MANAGEMENT = 'LOADING_FILTERED_USER_MANAGEMENT';
export const loadingFilter = (rxState, status) => ({
  type: LOADING_FILTERED_USER_MANAGEMENT,
  rxState,
  status,
});

export const SET_OPEN_ADD_DIALOG_STATUS = 'SET_OPEN_ADD_DIALOG_STATUS';
export const setOpenAddDialogStatus = (rxState, status) => ({
  type: SET_OPEN_ADD_DIALOG_STATUS,
  rxState,
  status,
});

export const SET_OPEN_UPDATE_DIALOG_STATUS = 'SET_OPEN_UPDATE_DIALOG_STATUS';
export const setOpenUpdateDialogStatus = (rxState, status) => ({
  type: SET_OPEN_UPDATE_DIALOG_STATUS,
  rxState,
  status,
});

export const SET_OPEN_DELETE_DIALOG_STATUS = 'SET_OPEN_DELETE_DIALOG_STATUS';
export const setOpenDeleteDialogStatus = (rxState, status) => ({
  type: SET_OPEN_DELETE_DIALOG_STATUS,
  rxState,
  status,
});

export const SET_DIALOG_LOADING = 'SET_DIALOG_LOADING';
export const setDialogLoadingStatus = (rxState, status) => ({
  type: SET_DIALOG_LOADING,
  rxState,
  status,
});

export const SET_SAVE_STATUS = 'SET_SAVE_STATUS';
export const setSavingStatus = (rxState, status) => ({
  type: SET_SAVE_STATUS,
  rxState,
  status,
});

export const SET_UPDATE_DIALOG_PARAM = 'SET_UPDATE_DIALOG_PARAM';
export const setUpdateDialogParam = (rxState, param) => ({
  type: SET_UPDATE_DIALOG_PARAM,
  rxState,
  param,
});

export const SET_DELETE_DIALOG_PARAM = 'SET_DELETE_DIALOG_PARAM';
export const setDeleteDialogParam = (rxState, param) => ({
  type: SET_DELETE_DIALOG_PARAM,
  rxState,
  param,
});


// Web User
export const TOGGLE_NOTIFICATION_DRAWER = 'TOGGLE_NOTIFICATION_DRAWER';
export const toggleNotificationDrawer = status => ({
  type: TOGGLE_NOTIFICATION_DRAWER,
  status,
});

export const SUBMITTING_MESSAGE = 'SUBMITTING_MESSAGE';
export const setSubmittingMessageStatus = status => ({
  type: SUBMITTING_MESSAGE,
  status,
});

const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};


// Cart
export const ADD_SHOPPING_CART_ITEM = 'ADD_SHOPPING_CART_ITEM';
export const addShoppingCartItem = makeActionCreator(ADD_SHOPPING_CART_ITEM, 'item');

export const ADDING_SHOPPING_CART_ITEM = 'ADDING_SHOPPING_CART_ITEM';
export const addingShoppingCartItem = makeActionCreator(ADDING_SHOPPING_CART_ITEM, 'status', 'id');

export const REMOVE_SHOPPING_CART_ITEM = 'REMOVE_SHOPPING_CART_ITEM';
export const removeShoppingCartItem = makeActionCreator(REMOVE_SHOPPING_CART_ITEM, 'itemId');

export const REMOVING_SHOPPING_CART_ITEM = 'REMOVING_SHOPPING_CART_ITEM';
export const removingShoppingCartItem = makeActionCreator(REMOVING_SHOPPING_CART_ITEM, 'status');


// Container Tracking
export const CLEAR_CONTAINER_TRACKINGS = 'CLEAR_CONTAINER_TRACKINGS';
export const clearContainerTrackings = makeActionCreator(CLEAR_CONTAINER_TRACKINGS);

export const ADD_CONTAINER_TRACKINGS = 'ADD_CONTAINER_TRACKINGS';
export const addContainerTrackings = makeActionCreator(ADD_CONTAINER_TRACKINGS, 'containerTrackings');

export const ADD_CONTAINER_TRACKING_DETAIL = 'ADD_CONTAINER_TRACKING_DETAIL';
export const addContainerTrackingDetail = makeActionCreator(ADD_CONTAINER_TRACKING_DETAIL, 'value');

export const DOWNLOADING_CONTAINER_TRACKINGS_DATA = 'DOWNLOADING_CONTAINER_TRACKINGS_DATA';
export const downloadingContainerTrackingsData = makeActionCreator(DOWNLOADING_CONTAINER_TRACKINGS_DATA, 'status');

export const SET_TAPPED_CONTAINER_TRACKING_BL_NO = 'SET_TAPPED_CONTAINER_TRACKING_BL_NO';
export const setTappedContainerTrackingBlNo = makeActionCreator(SET_TAPPED_CONTAINER_TRACKING_BL_NO, 'tappedBlNo');

export const SET_TAPPED_CONTAINER_TRACKING_ITEM_NO = 'SET_TAPPED_CONTAINER_TRACKING_ITEM_NO';
export const setTappedContainerTrackingItemNo = makeActionCreator(SET_TAPPED_CONTAINER_TRACKING_ITEM_NO, 'tappedItemNo');

export const SET_CONTAINER_TRACKING_SEARCH_TEXT = 'SET_CONTAINER_TRACKING_SEARCH_TEXT';
export const setContainerTrackingSearchText = makeActionCreator(SET_CONTAINER_TRACKING_SEARCH_TEXT, 'text');

export const SET_CONTAINER_TRACKING_DETAIL_SEARCH_TEXT = 'SET_CONTAINER_TRACKING_DETAIL_SEARCH_TEXT';
export const setContainerTrackingDetailSearchText = makeActionCreator(SET_CONTAINER_TRACKING_DETAIL_SEARCH_TEXT, 'text');

export const SET_CONTAINER_DETAIL_TAB_INDEX = 'SET_CONTAINER_DETAIL_TAB_INDEX';
export const setContainerDetailTabIndex = makeActionCreator(SET_CONTAINER_DETAIL_TAB_INDEX, 'tappedTabIndex');

// Container Tracking filters
export const SET_CONTAINER_TRACKING_FILTER = 'CONTAINER_TRACKING_FILTER';
export const setContainerTrackingFilter = (fieldName, value) => ({
  type: SET_CONTAINER_TRACKING_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_CONTAINER_TRACKING_PAGING = 'SET_CONTAINER_TRACKING_PAGING';
export const setContainerTrackingPaging = (pageField, value) => ({
  type: SET_CONTAINER_TRACKING_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_CONTAINER_TRACKING_SORT = 'SET_CONTAINER_TRACKING_SORT';
export const setContainerTrackingSort = (sortField, sortDirection) => ({
  type: SET_CONTAINER_TRACKING_SORT,
  sortField,
  sortDirection,
});


// Reefer Data
export const ADD_REEFER_DATA = 'ADD_REEFER_DATA';
export const addReeferData = makeActionCreator(ADD_REEFER_DATA, 'reeferData');

export const ADD_REEFER_DATA_DETAILS = 'ADD_REEFER_DATA_DETAILS';
export const addReeferDataDetails = makeActionCreator(ADD_REEFER_DATA_DETAILS, 'reeferDataDetails');

export const DOWNLOADING_REEFER_DATA_DETAILS = 'DOWNLOADING_REEFER_DATA_DETAILS';
export const downloadingReeferDataDetails = makeActionCreator(DOWNLOADING_REEFER_DATA_DETAILS, 'status');

export const SET_TAPPED_REEFER_DATA_BL_NO = 'SET_TAPPED_REEFER_DATA_BL_NO';
export const setTappedReeferDataBlNo = makeActionCreator(SET_TAPPED_REEFER_DATA_BL_NO, 'tappedBlNo');

export const DOWNLOADING_REEFER_DATA = 'DOWNLOADING_REEFER_DATA';
export const downloadingReeferData = makeActionCreator(DOWNLOADING_REEFER_DATA, 'status');

export const SET_TAPPED_REEFER_DATA_CONTAINER_NO = 'SET_TAPPED_REEFER_DATA_CONTAINER_NO';
export const setTappedReeferDataContainerNo = makeActionCreator(SET_TAPPED_REEFER_DATA_CONTAINER_NO, 'tappedContainerNo');

export const SET_REEFER_DATA_SEARCH_TEXT = 'SET_REEFER_DATA_SEARCH_TEXT';
export const setReeferDataSearchText = makeActionCreator(SET_REEFER_DATA_SEARCH_TEXT, 'text');

export const SET_REEFER_DATA_CONTAINER_SEARCH_TEXT = 'SET_REEFER_DATA_CONTAINER_SEARCH_TEXT';
export const setReeferDataContainerSearchText = makeActionCreator(SET_REEFER_DATA_CONTAINER_SEARCH_TEXT, 'text');

export const SET_REEFER_DATA_DETAIL_SEARCH_TEXT = 'SET_REEFER_DATA_DETAIL_SEARCH_TEXT';
export const setReeferDataDetailSearchText = makeActionCreator(SET_REEFER_DATA_DETAIL_SEARCH_TEXT, 'text');

export const SET_REEFER_DATA_DETAIL_DIALOG_STATUS = 'SET_REEFER_DATA_DETAIL_DIALOG_STATUS';
export const setReeferDataDetailDialogStatus = makeActionCreator(SET_REEFER_DATA_DETAIL_DIALOG_STATUS, 'status');

// Reefer Data filters
export const SET_REEFER_DATA_FILTER = 'SET_REEFER_DATA_FILTER';
export const setReeferDataFilter = (fieldName, value) => ({
  type: SET_REEFER_DATA_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_REEFER_DATA_PAGING = 'SET_REEFER_DATA_PAGING';
export const setReeferDataPaging = (pageField, value) => ({
  type: SET_REEFER_DATA_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_REEFER_DATA_SORT = 'SET_REEFER_DATA_SORT';
export const setReeferDataSort = (sortField, sortDirection) => ({
  type: SET_REEFER_DATA_SORT,
  sortField,
  sortDirection,
});


export const SET_REEFER_DATA_DETAIL_FILTER = 'SET_REEFER_DATA_DETAIL_FILTER';
export const setReeferDataDetailFilter = (fieldName, value) => ({
  type: SET_REEFER_DATA_DETAIL_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_REEFER_DATA_DETAIL_PAGING = 'SET_REEFER_DATA_DETAIL_PAGING';
export const setReeferDataDetailPaging = (pageField, value) => ({
  type: SET_REEFER_DATA_DETAIL_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_REEFER_DATA_DETAIL_SORT = 'SET_REEFER_DATA_DETAIL_SORT';
export const setReeferDataDetailSort = (sortField, sortDirection) => ({
  type: SET_REEFER_DATA_DETAIL_SORT,
  sortField,
  sortDirection,
});


// Tariff Simulation
export const ADD_TARIFF_SIMULATION_ORDER = 'ADD_TARIFF_SIMULATION_ORDER';
export const addTariffSimulationOrder = makeActionCreator(ADD_TARIFF_SIMULATION_ORDER, 'tariffSimulationOrder');

export const DOWNLOADING_TARIFF_SIMULATION_ORDER = 'DOWNLOADING_TARIFF_SIMULATION_ORDER';
export const downloadingTariffSimulationOrder = makeActionCreator(DOWNLOADING_TARIFF_SIMULATION_ORDER, 'status');

export const SET_TARIFF_SIMULATION_SEARCH_TEXT = 'SET_TARIFF_SIMULATION_SEARCH_TEXT';
export const setTariffSimulationSearchText = makeActionCreator(SET_TARIFF_SIMULATION_SEARCH_TEXT, 'text');

export const DOWNLOADING_TARIFF_SIMULATION_DATA = 'DOWNLOADING_TARIFF_SIMULATION_DATA';
export const downloadingTariffSimulationData = makeActionCreator(DOWNLOADING_TARIFF_SIMULATION_DATA, 'status');

export const ADD_TARIFF_SIMULATION_INFO = 'ADD_TARIFF_SIMULATION_INFO';
export const addTariffSimulation = makeActionCreator(ADD_TARIFF_SIMULATION_INFO, 'tariffSimulation');

export const SET_TARIFF_SIMULATION_ACTION = 'SET_TARIFF_SIMULATION_ACTION';
export const setTariffSimulationAction = makeActionCreator(SET_TARIFF_SIMULATION_ACTION, 'value');

export const SET_CHOSEN_TARIFF_SIMULATION_DATE = 'SET_CHOSEN_TARIFF_SIMULATION_DATE';
export const setChosenTariffSimulationDate = makeActionCreator(SET_CHOSEN_TARIFF_SIMULATION_DATE, 'chosenDate');

export const DOWNLOADING_TARIFF_SIMULATION_INFO = 'DOWNLOADING_TARIFF_SIMULATION_INFO';
export const downloadingTariffSimulationInfo = makeActionCreator(DOWNLOADING_TARIFF_SIMULATION_INFO, 'status');

export const SET_DIALOG_CALENDAR_STATUS = 'SET_DIALOG_CALENDAR_STATUS';
export const setDialogCalendarStatus = makeActionCreator(SET_DIALOG_CALENDAR_STATUS, 'status');

export const SET_SELECTED_TARIFF_SIMULATION_PAYMENT_BEHALF_OF = 'SET_SELECTED_TARIFF_SIMULATION_PAYMENT_BEHALF_OF';
export const setSelectedTariffSimulationPaymentBehalfOf = makeActionCreator(SET_SELECTED_TARIFF_SIMULATION_PAYMENT_BEHALF_OF, 'value');

export const SET_TARIFF_SIMULATION_PASSWORD_INPUT_DIALOG = 'SET_TARIFF_SIMULATION_PASSWORD_INPUT_DIALOG';
export const setTariffSimulationPasswordInputDialog = makeActionCreator(SET_TARIFF_SIMULATION_PASSWORD_INPUT_DIALOG, 'status');

export const SET_TARIFF_SIMULATION_PASSWORD_TEXT_INPUT = 'SET_TARIFF_SIMULATION_PASSWORD_TEXT_INPUT';
export const setTariffSimulationPasswordTextInput = makeActionCreator(SET_TARIFF_SIMULATION_PASSWORD_TEXT_INPUT, 'password');

// Tariff Simulation Filter
export const SET_TARIFF_SIMULATION_FILTER = 'SET_TARIFF_SIMULATION_FILTER';
export const setTariffSimulationFilter = (fieldName, value) => ({
  type: SET_TARIFF_SIMULATION_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_TARIFF_SIMULATION_PAGING = 'SET_TARIFF_SIMULATION_PAGING';
export const setTariffSimulationPaging = (pageField, value) => ({
  type: SET_TARIFF_SIMULATION_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_TARIFF_SIMULATION_SORT = 'SET_TARIFF_SIMULATION_SORT';
export const setTariffSimulationSort = (sortField, sortDirection) => ({
  type: SET_TARIFF_SIMULATION_SORT,
  sortField,
  sortDirection,
});


// Feeder Service
export const ADD_FEEDER_SERVICE_ORDER_HISTORIES = 'ADD_FEEDER_SERVICE_ORDER_HISTORIES';
export const addFeederServiceOrderHistories = makeActionCreator(ADD_FEEDER_SERVICE_ORDER_HISTORIES, 'feederServiceOrderHistories');

export const DOWNLOADING_FEEDER_SERVICE_ORDER_HISTORIES = 'DOWNLOADING_FEEDER_SERVICE_ORDER_HISTORIES';
export const downloadingFeederServiceOrderHistories = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_ORDER_HISTORIES, 'status');

export const DOWNLOADING_FEEDER_SERVICE_ORDER_DETAIL = 'DOWNLOADING_FEEDER_SERVICE_ORDER_DETAIL';
export const downloadingFeederServiceOrderDetail = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_ORDER_DETAIL, 'status');

export const ADD_FEEDER_SERVICE_ORDER_DETAILS = 'ADD_FEEDER_SERVICE_ORDER_DETAILS';
export const addFeederServiceOrderDetails = makeActionCreator(ADD_FEEDER_SERVICE_ORDER_DETAILS, 'orderDetails');

export const SET_TAPPED_FEEDER_SERVICE_BL_NO = 'SET_TAPPED_FEEDER_SERVICE_BL_NO';
export const setTappedFeederServiceBlNo = makeActionCreator(SET_TAPPED_FEEDER_SERVICE_BL_NO, 'tappedBlNo');

export const SET_TAPPED_FEEDER_SERVICE_ITEM_NO = 'SET_TAPPED_FEEDER_SERVICE_ITEM_NO';
export const setTappedFeederServiceItemNo = makeActionCreator(SET_TAPPED_FEEDER_SERVICE_ITEM_NO, 'tappedItemNo');

export const SET_FEEDER_SERVICE_SEARCH_TEXT = 'SET_FEEDER_SERVICE_SEARCH_TEXT';
export const setFeederServiceSearchText = makeActionCreator(SET_FEEDER_SERVICE_SEARCH_TEXT, 'text');

export const SET_FEEDER_SERVICE_DETAIL_SEARCH_TEXT = 'SET_FEEDER_SERVICE_DETAIL_SEARCH_TEXT';
export const setFeederServiceDetailSearchText = makeActionCreator(SET_FEEDER_SERVICE_DETAIL_SEARCH_TEXT, 'text');

export const SET_FEEDER_SERVICE_TAPPED_CONTAINER_NO = 'SET_FEEDER_SERVICE_TAPPED_CONTAINER_NO';
export const setFeederServiceDetailTappedContainerNo = makeActionCreator(SET_FEEDER_SERVICE_TAPPED_CONTAINER_NO, 'tappedContainerNo');

// Feeder Service Filter
export const SET_FEEDER_SERVICE_FILTER = 'SET_FEEDER_SERVICE_FILTER';
export const setFeederServiceFilter = (fieldName, value) => ({
  type: SET_FEEDER_SERVICE_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_FEEDER_SERVICE_PAGING = 'SET_FEEDER_SERVICE_PAGING';
export const setFeederServicePaging = (pageField, value) => ({
  type: SET_FEEDER_SERVICE_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_FEEDER_SERVICE_SORT = 'SET_FEEDER_SERVICE_SORT';
export const setFeederServiceSort = (sortField, sortDirection) => ({
  type: SET_FEEDER_SERVICE_SORT,
  sortField,
  sortDirection,
});

// add to term
export const SET_FEEDER_SERVICE_PASSWORD_INPUT_DIALOG = 'SET_FEEDER_SERVICE_PASSWORD_INPUT_DIALOG';
export const setFeederServicePasswordInputDialog = makeActionCreator(SET_FEEDER_SERVICE_PASSWORD_INPUT_DIALOG, 'status');

export const SET_FEEDER_SERVICE_PASSWORD_TEXT_INPUT = 'SET_FEEDER_SERVICE_PASSWORD_TEXT_INPUT';
export const setFeederServicePasswordTextInput = makeActionCreator(SET_FEEDER_SERVICE_PASSWORD_TEXT_INPUT, 'password');

// Book New
export const ADD_BOOK_FEEDER_SERVICE = 'ADD_BOOK_FEEDER_SERVICE';
export const addBookFeederService = makeActionCreator(ADD_BOOK_FEEDER_SERVICE, 'bookFeederServiceInfo');

export const REMOVE_BOOK_FEEDER_SERVICE_INFO = 'REMOVE_BOOK_FEEDER_SERVICE_INFO';
export const removeBookFeederServiceinfo = makeActionCreator(REMOVE_BOOK_FEEDER_SERVICE_INFO);

export const UPLOADING_NEW_BOOKING = 'UPLOADING_NEW_BOOKING';
export const setUploadingNewBookingStatus = makeActionCreator(UPLOADING_NEW_BOOKING, 'tappedContainerNo');

export const DOWNLOADING_BOOK_FEEDER_LIST = 'DOWNLOADING_BOOK_FEEDER_LIST';
export const downloadingBookFeederList = makeActionCreator(DOWNLOADING_BOOK_FEEDER_LIST, 'status');

export const ADD_FEEDER_SERVICE_FORWARDER_LIST = 'ADD_FEEDER_SERVICE_FORWARDER_LIST';
export const addFeederServiceForwarderList = makeActionCreator(ADD_FEEDER_SERVICE_FORWARDER_LIST, 'forwarderList');

export const DOWNLOADING_FEEDER_SERVICE_FORWARDER_LIST = 'DOWNLOADING_FEEDER_SERVICE_FORWARDER_LIST';
export const downloadingFeederServiceForwarderList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_FORWARDER_LIST, 'status');

export const ADD_FEEDER_SERVICE_EMPTY_DEPO_LIST = 'ADD_FEEDER_SERVICE_EMPTY_DEPO_LIST';
export const addFeederServiceEmptyDepoList = makeActionCreator(ADD_FEEDER_SERVICE_EMPTY_DEPO_LIST, 'emptyDepoList');

export const DOWNLOADING_FEEDER_SERVICE_EMPTY_DEPO_LIST = 'DOWNLOADING_FEEDER_SERVICE_EMPTY_DEPO_LIST';
export const downloadingFeederServiceEmptyDepoList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_EMPTY_DEPO_LIST, 'status');

export const ADD_FEEDER_SERVICE_AREA_LIST = 'ADD_FEEDER_SERVICE_AREA_LIST';
export const addFeederServiceAreaList = makeActionCreator(ADD_FEEDER_SERVICE_AREA_LIST, 'areaList');

export const DOWNLOADING_FEEDER_SERVICE_AREA_LIST = 'DOWNLOADING_FEEDER_SERVICE_AREA_LIST';
export const downloadingFeederServiceAreaList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_AREA_LIST, 'status');

export const ADD_FEEDER_SERVICE_WAREHOUSE_LIST = 'ADD_FEEDER_SERVICE_WAREHOUSE_LIST';
export const addFeederServiceWarehouseList = makeActionCreator(ADD_FEEDER_SERVICE_WAREHOUSE_LIST, 'warehouseList');

export const DOWNLOADING_FEEDER_SERVICE_WAREHOUSE_LIST = 'DOWNLOADING_FEEDER_SERVICE_WAREHOUSE_LIST';
export const downloadingFeederServiceWarehouseList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_WAREHOUSE_LIST, 'status');

export const ADD_FEEDER_SERVICE_BOOK_PARTY_LIST = 'ADD_FEEDER_SERVICE_BOOK_PARTY_LIST';
export const addFeederServiceBookPartyList = makeActionCreator(ADD_FEEDER_SERVICE_BOOK_PARTY_LIST, 'bookPartyList');

export const SET_FEEDER_SERVICE_BL_LIST = 'SET_FEEDER_SERVICE_BL_LIST';
export const setFeederServiceBLList = makeActionCreator(SET_FEEDER_SERVICE_BL_LIST, 'feederServiceBLList');

export const DOWNLOADING_FEEDER_SERVICE_DISCLAIMER = 'DOWNLOADING_FEEDER_SERVICE_DISCLAIMER';
export const downloadingFeederServiceDisclaimer = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_DISCLAIMER, 'status');

export const SET_FEEDER_SERVICE_DISCLAIMER = 'SET_FEEDER_SERVICE_DISCLAIMER';
export const setFeederServiceDisclaimer = makeActionCreator(SET_FEEDER_SERVICE_DISCLAIMER, 'disclaimer');


export const DOWNLOADING_FEEDER_SERVICE_BOOK_PARTY_LIST = 'DOWNLOADING_FEEDER_SERVICE_BOOK_PARTY_LIST';
export const downloadingFeederServiceBookPartyList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_BOOK_PARTY_LIST, 'status');

export const DOWNLOADING_FEEDER_SERVICE_BL_LIST = 'DOWNLOADING_FEEDER_SERVICE_BL_LIST';
export const downloadingFeederServiceBLList = makeActionCreator(DOWNLOADING_FEEDER_SERVICE_BL_LIST, 'status');


export const ADD_PPJK_COMPANY_LIST = 'ADD_PPJK_COMPANY_LIST';
export const addPpjkCompanyList = makeActionCreator(ADD_PPJK_COMPANY_LIST, 'ppjkCompanyList');

export const DOWNLOADING_PPJK_COMPANY_LIST = 'DOWNLOADING_PPJK_COMPANY_LIST';
export const downloadingPpjkCompanyList = makeActionCreator(DOWNLOADING_PPJK_COMPANY_LIST, 'status');

export const ADD_CUSTOMER_COMPANY_LIST = 'ADD_CUSTOMER_COMPANY_LIST';
export const addCustomerCompanyList = makeActionCreator(ADD_CUSTOMER_COMPANY_LIST, 'customerCompanyList');

export const DOWNLOADING_CUSTOMER_COMPANY_LIST = 'DOWNLOADING_CUSTOMER_COMPANY_LIST';
export const downloadingCustomerCompanyList = makeActionCreator(DOWNLOADING_CUSTOMER_COMPANY_LIST, 'status');

export const ADD_BOOK_FEEDER_PRICE_CONTENT = 'ADD_BOOK_FEEDER_PRICE_CONTENT';
export const addBookFeederPriceContent = makeActionCreator(ADD_BOOK_FEEDER_PRICE_CONTENT, 'info');

export const ADD_SELECTED_COMPANY = 'ADD_SELECTED_COMPANY';
export const addSelectedCompany = makeActionCreator(ADD_SELECTED_COMPANY, 'selectedCompany');

export const BOOKING_FEEDER_SERVICE = 'BOOKING_FEEDER_SERVICE';
export const bookingFeederService = status => ({
  type: BOOKING_FEEDER_SERVICE,
  status,
});

export const ADD_BOOK_NO = 'ADD_BOOK_NO';
export const addBookNo = makeActionCreator(ADD_BOOK_NO, 'bookNo');

export const ADD_BL_NO = 'ADD_BL_NO';
export const addBlNo = makeActionCreator(ADD_BL_NO, 'blNo');

export const ADD_TRUCKING_FEEDER_BL_NO = 'ADD_TRUCKING_FEEDER_BL_NO';
export const addTruckingFeederBlNo = makeActionCreator(ADD_TRUCKING_FEEDER_BL_NO, 'value');

export const ADD_TRUCKING_FEEDER_DO_NO = 'ADD_TRUCKING_FEEDER_DO_NO';
export const addTruckingFeederDoNo = makeActionCreator(ADD_TRUCKING_FEEDER_DO_NO, 'value');

export const ADD_TRUCKING_FEEDER_DO_DATE = 'ADD_TRUCKING_FEEDER_DO_DATE';
export const addTruckingFeederDoDate = makeActionCreator(ADD_TRUCKING_FEEDER_DO_DATE, 'value');

export const ADD_TRUCKING_FEEDER_BILL_TO = 'ADD_TRUCKING_FEEDER_BILL_TO';
export const addTruckingFeederBillTo = makeActionCreator(ADD_TRUCKING_FEEDER_BILL_TO, 'value');

// export const SET_BOOK_FEEDER_ACTIVE_FORM = 'SET_BOOK_FEEDER_ACTIVE_FORM';
// export const setBookFeederActiveForm = activeForm => ({
//   type: SET_BOOK_FEEDER_ACTIVE_FORM,
//   activeForm,
// });

export const SET_NEW_BOOKING_DIALOG_STATUS = 'SET_NEW_BOOKING_DIALOG_STATUS';
export const setNewBookingDialogStatus = makeActionCreator(SET_NEW_BOOKING_DIALOG_STATUS, 'status');

export const SET_BOOK_FEEDER_ACTIVE_FORM = 'SET_BOOK_FEEDER_ACTIVE_FORM';
export const setBookFeederActiveForm = makeActionCreator(SET_BOOK_FEEDER_ACTIVE_FORM, 'activeForm');

export const REMOVE_UPLOADED_ATTACHMENT = 'REMOVE_UPLOADED_ATTACHMENT';
export const removeUploadedAttachment = uploadedFileId => ({
  type: REMOVE_UPLOADED_ATTACHMENT, uploadedFileId,
});

export const UPLOADING_BOOK_PARTY_DOCUMENT = 'UPLOADING_BOOK_PARTY_DOCUMENT';
export const uploadingBookPartyDocument = makeActionCreator(UPLOADING_BOOK_PARTY_DOCUMENT, 'status');

export const SET_EXPANDED_DROPDOWN_ARRAY = 'SET_EXPANDED_DROPDOWN_ARRAY';
export const setExpandedDropdownArray = id => ({
  type: SET_EXPANDED_DROPDOWN_ARRAY,
  id,
});

export const SET_COLLAPSED_DROPDOWN_ARRAY = 'SET_COLLAPSED_DROPDOWN_ARRAY';
export const setCollapsedDropdownArray = id => ({
  type: SET_COLLAPSED_DROPDOWN_ARRAY,
  id,
});

export const SET_UPLOADED_ATTACHMENT = 'SET_UPLOADED_ATTACHMENT';
export const setUploadedAttachment = (uploadedFile, uploadedFileName, uploadedFileSize) => ({
  type: SET_UPLOADED_ATTACHMENT, uploadedFile, uploadedFileName, uploadedFileSize,
});


export const SET_EDIT_PARTY = 'SET_EDIT_PARTY';
export const setEditParty = makeActionCreator(SET_EDIT_PARTY, 'value');

export const REMOVE_EDIT_PARTY = 'REMOVE_EDIT_PARTY';
export const removeEditParty = makeActionCreator(REMOVE_EDIT_PARTY, 'value');

export const MODIFY_BOOK_PARTY = 'MODIFY_BOOK_PARTY';
export const modifyBookParty = makeActionCreator(MODIFY_BOOK_PARTY, 'partyId', 'info');

export const ADD_BOOK_PARTY = 'ADD_BOOK_PARTY';
export const addBookParty = makeActionCreator(ADD_BOOK_PARTY, 'info');

export const REMOVE_BOOK_PARTY = 'REMOVE_BOOK_PARTY';
export const removeBookParty = makeActionCreator(REMOVE_BOOK_PARTY, 'partyId');

export const SET_NEW_PARTY = 'SET_NEW_PARTY';
export const setNewParty = makeActionCreator(SET_NEW_PARTY, 'partyId');

export const REMOVE_NEW_PARTY = 'REMOVE_NEW_PARTY';
export const removeNewParty = makeActionCreator(REMOVE_NEW_PARTY, 'partyId');

export const CLEAR_BOOK_EXPORT_PARTIES = 'CLEAR_BOOK_EXPORT_PARTIES';
export const clearBookExportParties = makeActionCreator(CLEAR_BOOK_EXPORT_PARTIES);

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const showSnackbar = (title, message) => ({
  type: SHOW_SNACKBAR,
  title,
  message,
});

export const SHOW_ERROR_SNACKBAR = 'SHOW_ERROR_SNACKBAR';
export const showErrorSnackbar = (title, message) => ({
  type: SHOW_ERROR_SNACKBAR,
  title,
  message,
});

export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});


export const SET_CART_ITEM_CHECKED = 'SET_CART_ITEM_CHECKED';
export const setCartItemChecked = makeActionCreator(SET_CART_ITEM_CHECKED, 'itemId');

export const REMOVE_CART_ITEM_CHECKED = 'REMOVE_CART_ITEM_CHECKED';
export const removeCartItemChecked = makeActionCreator(REMOVE_CART_ITEM_CHECKED, 'itemId');

export const EMPTY_CART_ITEM_CHECKED = 'EMPTY_CART_ITEM_CHECKED';
export const emptyCartItemChecked = () => ({ type: EMPTY_CART_ITEM_CHECKED });

export const SET_EXPANDED_CART_ITEM = 'SET_EXPANDED_CART_ITEM';
export const setExpandedCartItem = makeActionCreator(SET_EXPANDED_CART_ITEM, 'blNumber');

export const REMOVE_EXPANDED_CART_ITEM = 'REMOVE_EXPANDED_CART_ITEM';
export const removeExpandedCartItem = makeActionCreator(REMOVE_EXPANDED_CART_ITEM, 'blNumber');

export const ADD_TO_DELETE_ITEM = 'ADD_TO_DELETE_ITEM';
export const addToDeleteItem = makeActionCreator(ADD_TO_DELETE_ITEM, 'item');

export const EMPTY_TO_DELETE_ITEM = 'EMPTY_TO_DELETE_ITEM';
export const emptyToDeleteItem = () => ({ type: EMPTY_TO_DELETE_ITEM });

// checkout
export const ADD_CHECKOUT_ITEM = 'ADD_CHECKOUT_ITEM';
export const addCheckoutItem = makeActionCreator(ADD_CHECKOUT_ITEM, 'item');

export const ADDING_CHECKOUT_ITEM = 'ADDING_CHECKOUT_ITEM';
export const addingCheckoutItem = makeActionCreator(ADDING_CHECKOUT_ITEM, 'status');

export const REMOVE_CHECKOUT_ITEM = 'REMOVE_CHECKOUT_ITEM';
export const removeCheckoutItem = makeActionCreator(REMOVE_CHECKOUT_ITEM, 'itemId');

export const REMOVE_ALL_CHECKOUT_ITEMS = 'REMOVE_ALL_CHECKOUT_ITEMS';
export const removeAllCheckoutItem = makeActionCreator(REMOVE_ALL_CHECKOUT_ITEMS);

export const REMOVING_CHECKOUT_ITEM = 'REMOVING_CHECKOUT_ITEM';
export const removingCheckoutItem = makeActionCreator(REMOVING_CHECKOUT_ITEM, 'status');

export const SET_EXPANDED_CHECKOUT_ITEM = 'SET_EXPANDED_CHECKOUT_ITEM';
export const setExpandedCheckoutItem = makeActionCreator(SET_EXPANDED_CHECKOUT_ITEM, 'blNumber');

export const REMOVE_EXPANDED_CHECKOUT_ITEM = 'REMOVE_EXPANDED_CHECKOUT_ITEM';
export const removeExpandedCheckoutItem = makeActionCreator(REMOVE_EXPANDED_CHECKOUT_ITEM, 'blNumber');

export const SET_CHECKOUT_PASSWORD_TEXT_INPUT = 'SET_CHECKOUT_PASSWORD_TEXT_INPUT';
export const setCheckoutPasswordTextInput = makeActionCreator(SET_CHECKOUT_PASSWORD_TEXT_INPUT, 'value');

export const SET_CHECKOUT_BANK_SELECTION = 'SET_CHECKOUT_BANK_SELECTION';
export const setCheckoutBankSelection = makeActionCreator(SET_CHECKOUT_BANK_SELECTION, 'value');

export const ADD_PAYMENT_ID = 'ADD_PAYMENT_ID';
export const addPaymentId = makeActionCreator(ADD_PAYMENT_ID, 'id');

export const SET_UPLOADING_PAYMENT = 'SET_UPLOADING_PAYMENT';
export const setUploadingPayment = value => ({
  type: SET_UPLOADING_PAYMENT,
  value,
});

export const SET_DIALOG_PAYMENT_CONFIRM_STATUS = 'SET_DIALOG_PAYMENT_CONFIRM_STATUS';
export const setDialogPaymentConfirmStatus = value => ({
  type: SET_DIALOG_PAYMENT_CONFIRM_STATUS,
  value,
});

export const SET_DIALOG_PAYMENT_COMPLETE_STATUS = 'SET_DIALOG_PAYMENT_COMPLETE_STATUS';
export const setDialogPaymentCompleteStatus = value => ({
  type: SET_DIALOG_PAYMENT_COMPLETE_STATUS,
  value,
});

export const SET_DIALOG_SELECT_BANK_STATUS = 'SET_DIALOG_SELECT_BANK_STATUS';
export const setDialogSelectBankStatus = value => ({
  type: SET_DIALOG_SELECT_BANK_STATUS,
  value,
});

export const SET_DIALOG_CHECKOUT_TERM_AND_CONDITION = 'SET_DIALOG_CHECKOUT_TERM_AND_CONDITION';
export const setDialogCheckoutTermAndCondition = makeActionCreator(SET_DIALOG_CHECKOUT_TERM_AND_CONDITION, 'value');

export const UPDATE_DEPOSIT_BALANCE = 'UPDATE_DEPOSIT_BALANCE';
export const updateDepositBalance = makeActionCreator(UPDATE_DEPOSIT_BALANCE, 'balance');

export const UPDATING_DEPOSIT_BALANCE = 'UPDATING_DEPOSIT_BALANCE';
export const updatingDepositBalance = makeActionCreator(UPDATING_DEPOSIT_BALANCE, 'status');

export const SET_DEPOSIT_BALANCE_NEXT_AUTO_UPDATE = 'SET_DEPOSIT_BALANCE_NEXT_AUTO_UPDATE';
export const setDepositBalanceNextAutoUpdate = makeActionCreator(SET_DEPOSIT_BALANCE_NEXT_AUTO_UPDATE, 'value');

export const SET_PAYMENT_RESULT = 'SET_PAYMENT_RESULT';
export const setPaymentResult = makeActionCreator(SET_PAYMENT_RESULT, 'result');

export const START_PAYMENT_PROCESS = 'START_PAYMENT_PROCESS';
export const startPaymentProcess = makeActionCreator(START_PAYMENT_PROCESS, 'status');

export const DOWNLOADING_CHECKOUT_TERM_AND_CONDITIONS = 'DOWNLOADING_CHECKOUT_TERM_AND_CONDITIONS';
export const downloadingCheckoutTermAndConditions = makeActionCreator(DOWNLOADING_CHECKOUT_TERM_AND_CONDITIONS, 'status');

export const SET_CHECKOUT_TERM_AND_CONDITIONS_CHECKED = 'SET_CHECKOUT_TERM_AND_CONDITIONS_CHECKED';
export const setCheckoutTermsAndConditionChecked = makeActionCreator(SET_CHECKOUT_TERM_AND_CONDITIONS_CHECKED, 'status');

export const SET_CHECKOUT_TERM_AND_CONDITIONS_TEXT = 'SET_CHECKOUT_TERM_AND_CONDITIONS_TEXT';
export const setCheckoutTermsAndConditionText = makeActionCreator(SET_CHECKOUT_TERM_AND_CONDITIONS_TEXT, 'value');

// Master Vendor
// Master Vendor Filter
export const SET_MASTER_VENDOR_FILTER = 'SET_MASTER_VENDOR_FILTER';
export const setMasterVendorFilter = (fieldName, value) => ({
  type: SET_MASTER_VENDOR_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MASTER_VENDOR_PAGING = 'SET_MASTER_VENDOR_PAGING';
export const setMasterVendorPaging = (pageField, value) => ({
  type: SET_MASTER_VENDOR_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MASTER_VENDOR_SORT = 'SET_MASTER_VENDOR_SORT';
export const setMasterVendorSort = (sortField, sortDirection) => ({
  type: SET_MASTER_VENDOR_SORT,
  sortField,
  sortDirection,
});

export const SET_MASTER_VENDOR_SELECTED_ID = 'SET_MASTER_VENDOR_SELECTED_ID';
export const setMasterVendorSelectedId = makeActionCreator(SET_MASTER_VENDOR_SELECTED_ID, 'value');

export const SET_MASTER_VENDOR_DETAIL_DIALOG_STATUS = 'SET_MASTER_VENDOR_DETAIL_DIALOG_STATUS';
export const setMasterVendorDetailDialogStatus = makeActionCreator(SET_MASTER_VENDOR_DETAIL_DIALOG_STATUS, 'value');

export const SET_MASTER_VENDOR_ADD_DIALOG_STATUS = 'SET_MASTER_VENDOR_ADD_DIALOG_STATUS';
export const setMasterVendorAddDialogStatus = makeActionCreator(SET_MASTER_VENDOR_ADD_DIALOG_STATUS, 'value');

export const DOWNLOADING_VENDOR_LIST = 'DOWNLOADING_VENDOR_LIST';
export const downloadingVendors = makeActionCreator(DOWNLOADING_VENDOR_LIST, 'value');

export const ADD_VENDOR = 'ADD_VENDOR';
export const addVendor = makeActionCreator(ADD_VENDOR, 'value');

export const REMOVE_ALL_VENDORS = 'REMOVE_ALL_VENDORS';
export const removeAllVendors = makeActionCreator(REMOVE_ALL_VENDORS, 'value');

// Master Truck
// Master Truck Filter
export const SET_MASTER_TRUCK_FILTER = 'SET_MASTER_TRUCK_FILTER';
export const setMasterTruckFilter = (fieldName, value) => ({
  type: SET_MASTER_TRUCK_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MASTER_TRUCK_PAGING = 'SET_MASTER_TRUCK_PAGING';
export const setMasterTruckPaging = (pageField, value) => ({
  type: SET_MASTER_TRUCK_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MASTER_TRUCK_SORT = 'SET_MASTER_TRUCK_SORT';
export const setMasterTruckSort = (sortField, sortDirection) => ({
  type: SET_MASTER_TRUCK_SORT,
  sortField,
  sortDirection,
});

export const SET_MASTER_TRUCK_SELECTED_ID = 'SET_MASTER_TRUCK_SELECTED_ID';
export const setMasterTruckSelectedId = makeActionCreator(SET_MASTER_TRUCK_SELECTED_ID, 'value');

export const SET_MASTER_TRUCK_DETAIL_DIALOG_STATUS = 'SET_MASTER_TRUCK_DETAIL_DIALOG_STATUS';
export const setMasterTruckDetailDialogStatus = makeActionCreator(SET_MASTER_TRUCK_DETAIL_DIALOG_STATUS, 'value');

export const SET_MASTER_TRUCK_ADD_DIALOG_STATUS = 'SET_MASTER_TRUCK_ADD_DIALOG_STATUS';
export const setMasterTruckAddDialogStatus = makeActionCreator(SET_MASTER_TRUCK_ADD_DIALOG_STATUS, 'value');

export const DOWNLOADING_TRUCKS = 'DOWNLOADING_TRUCKS';
export const downloadingTrucks = makeActionCreator(DOWNLOADING_TRUCKS, 'value');

export const ADD_TRUCK = 'ADD_TRUCK';
export const addTruck = makeActionCreator(ADD_TRUCK, 'value');

export const REMOVE_ALL_TRUCKS = 'REMOVE_ALL_TRUCKS';
export const removeAllTrucks = makeActionCreator(REMOVE_ALL_TRUCKS, 'value');

// Master Forwarder
// Master Forwarder Filter
export const SET_MASTER_FORWARDER_FILTER = 'SET_MASTER_FORWARDER_FILTER';
export const setMasterForwarderFilter = (fieldName, value) => ({
  type: SET_MASTER_FORWARDER_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MASTER_FORWARDER_PAGING = 'SET_MASTER_FORWARDER_PAGING';
export const setMasterForwarderPaging = (pageField, value) => ({
  type: SET_MASTER_FORWARDER_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MASTER_FORWARDER_SORT = 'SET_MASTER_FORWARDER_SORT';
export const setMasterForwarderSort = (sortField, sortDirection) => ({
  type: SET_MASTER_FORWARDER_SORT,
  sortField,
  sortDirection,
});

export const SET_MASTER_FORWARDER_SELECTED_ID = 'SET_MASTER_FORWARDER_SELECTED_ID';
export const setMasterForwarderSelectedId = makeActionCreator(SET_MASTER_FORWARDER_SELECTED_ID, 'value');

export const SET_MASTER_FORWARDER_DETAIL_DIALOG_STATUS = 'SET_MASTER_FORWARDER_DETAIL_DIALOG_STATUS';
export const setMasterForwarderDetailDialogStatus = makeActionCreator(SET_MASTER_FORWARDER_DETAIL_DIALOG_STATUS, 'value');

export const SET_MASTER_FORWARDER_ADD_DIALOG_STATUS = 'SET_MASTER_FORWARDER_ADD_DIALOG_STATUS';
export const setMasterForwarderAddDialogStatus = makeActionCreator(SET_MASTER_FORWARDER_ADD_DIALOG_STATUS, 'value');

export const DOWNLOADING_FORWARDER_LIST = 'DOWNLOADING_FORWARDER_LIST';
export const downloadingForwarders = makeActionCreator(DOWNLOADING_FORWARDER_LIST, 'value');

export const ADD_FORWARDER = 'ADD_FORWARDER';
export const addForwarder = makeActionCreator(ADD_FORWARDER, 'value');

export const REMOVE_ALL_FORWARDERS = 'REMOVE_ALL_FORWARDERS';
export const removeAllForwarders = makeActionCreator(REMOVE_ALL_FORWARDERS, 'value');

// Order Detail Customer
// Order Detail Customer Filter
export const SET_ORDER_DETAIL_CUSTOMER_FILTER = 'SET_ORDER_DETAIL_CUSTOMER_FILTER';
export const setOrderDetailCustomerFilter = (fieldName, value) => ({
  type: SET_ORDER_DETAIL_CUSTOMER_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ORDER_DETAIL_CUSTOMER_PAGING = 'SET_ORDER_DETAIL_CUSTOMER_PAGING';
export const setOrderDetailCustomerPaging = (pageField, value) => ({
  type: SET_ORDER_DETAIL_CUSTOMER_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ORDER_DETAIL_CUSTOMER_SORT = 'SET_ORDER_DETAIL_CUSTOMER_SORT';
export const setOrderDetailCustomerSort = (sortField, sortDirection) => ({
  type: SET_ORDER_DETAIL_CUSTOMER_SORT,
  sortField,
  sortDirection,
});

export const SET_ORDER_DETAIL_CUSTOMER_SELECTED_TAB = 'SET_ORDER_DETAIL_CUSTOMER_SELECTED_TAB';
export const setOrderDetailCustomerSelectedTab = value => ({
  type: SET_ORDER_DETAIL_CUSTOMER_SELECTED_TAB,
  value,
});

// Order Detail Vendor
// Order Detail Vendor Filter
export const SET_ORDER_DETAIL_VENDOR_FILTER = 'SET_ORDER_DETAIL_VENDOR_FILTER';
export const setOrderDetailVendorFilter = (fieldName, value) => ({
  type: SET_ORDER_DETAIL_VENDOR_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ORDER_DETAIL_VENDOR_PAGING = 'SET_ORDER_DETAIL_VENDOR_PAGING';
export const setOrderDetailVendorPaging = (pageField, value) => ({
  type: SET_ORDER_DETAIL_VENDOR_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ORDER_DETAIL_VENDOR_SORT = 'SET_ORDER_DETAIL_VENDOR_SORT';
export const setOrderDetailVendorSort = (sortField, sortDirection) => ({
  type: SET_ORDER_DETAIL_VENDOR_SORT,
  sortField,
  sortDirection,
});

export const SET_ORDER_DETAIL_VENDOR_SELECTED_TAB = 'SET_ORDER_DETAIL_VENDOR_SELECTED_TAB';
export const setOrderDetailVendorSelectedTab = value => ({
  type: SET_ORDER_DETAIL_VENDOR_SELECTED_TAB,
  value,
});

// Order List Customer
// Order List Customer Filter
export const SET_ORDER_LIST_CUSTOMER_FILTER = 'SET_ORDER_LIST_CUSTOMER_FILTER';
export const setOrderListCustomerFilter = (fieldName, value) => ({
  type: SET_ORDER_LIST_CUSTOMER_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ORDER_LIST_CUSTOMER_PAGING = 'SET_ORDER_LIST_CUSTOMER_PAGING';
export const setOrderListCustomerPaging = (pageField, value) => ({
  type: SET_ORDER_LIST_CUSTOMER_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ORDER_LIST_CUSTOMER_SORT = 'SET_ORDER_LIST_CUSTOMER_SORT';
export const setOrderListCustomerSort = (sortField, sortDirection) => ({
  type: SET_ORDER_LIST_CUSTOMER_SORT,
  sortField,
  sortDirection,
});


// Order List Export In
// Order List Export In Filter
export const SET_ORDER_LIST_EXPORT_IN_FILTER = 'SET_ORDER_LIST_EXPORT_IN_FILTER';
export const setOrderListExportInFilter = (fieldName, value) => ({
  type: SET_ORDER_LIST_EXPORT_IN_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ORDER_LIST_EXPORT_IN_PAGING = 'SET_ORDER_LIST_EXPORT_IN_PAGING';
export const setOrderListExportInPaging = (pageField, value) => ({
  type: SET_ORDER_LIST_EXPORT_IN_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ORDER_LIST_EXPORT_IN_SORT = 'SET_ORDER_LIST_EXPORT_IN_SORT';
export const setOrderListExportInSort = (sortField, sortDirection) => ({
  type: SET_ORDER_LIST_EXPORT_IN_SORT,
  sortField,
  sortDirection,
});

// Export
export const ADD_EXPORT_ORDER_HISTORY = 'ADD_EXPORT_ORDER_HISTORY';
export const addExportOrderHistory = makeActionCreator(ADD_EXPORT_ORDER_HISTORY, 'exportOrderHistory');

export const DOWNLOADING_EXPORT_ORDER_HISTORY = 'DOWNLOADING_EXPORT_ORDER_HISTORY';
export const downloadingExportOrderHistory = makeActionCreator(DOWNLOADING_EXPORT_ORDER_HISTORY, 'status');

export const SET_EXPORT_SEARCH_TEXT = 'SET_EXPORT_SEARCH_TEXT';
export const setExportSearchText = makeActionCreator(SET_EXPORT_SEARCH_TEXT, 'text');

export const SET_TAPPED_EXPORT_BL_NO = 'SET_TAPPED_EXPORT_BL_NO';
export const setTappedExportBlNo = makeActionCreator(SET_TAPPED_EXPORT_BL_NO, 'tappedBlNo');

export const DOWNLOADING_BOOK_EXPORT_LIST = 'DOWNLOADING_BOOK_EXPORT_LIST';
export const downloadingBookExportList = makeActionCreator(DOWNLOADING_BOOK_EXPORT_LIST, 'status');

export const SET_EXPORT_PASSWORD_INPUT_DIALOG = 'SET_EXPORT_PASSWORD_INPUT_DIALOG';
export const setExportPasswordInputDialog = makeActionCreator(SET_EXPORT_PASSWORD_INPUT_DIALOG, 'status');

export const SET_EXPORT_PASSWORD_TEXT_INPUT = 'SET_EXPORT_PASSWORD_TEXT_INPUT';
export const setExportPasswordTextInput = makeActionCreator(SET_EXPORT_PASSWORD_TEXT_INPUT, 'password');

// Export Detail
export const ADD_EXPORT_ORDER_HISTORY_DETAIL = 'ADD_EXPORT_ORDER_HISTORY_DETAIL';
export const addExportOrderHistoryDetail = makeActionCreator(ADD_EXPORT_ORDER_HISTORY_DETAIL, 'exportOrderHistoryDetail');

export const DOWNLOADING_EXPORT_ORDER_HISTORY_DETAIL = 'DOWNLOADING_EXPORT_ORDER_HISTORY_DETAIL';
export const downloadingExportOrderHistoryDetail = makeActionCreator(DOWNLOADING_EXPORT_ORDER_HISTORY_DETAIL, 'status');

export const SET_EXPORT_DETAIL_SEARCH_TEXT = 'SET_EXPORT_DETAIL_SEARCH_TEXT';
export const setExportDetailSearchText = makeActionCreator(SET_EXPORT_DETAIL_SEARCH_TEXT, 'text');

export const SET_TAPPED_EXPORT_CONTAINER_NO = 'SET_TAPPED_EXPORT_CONTAINER_NO';
export const setTappedExportContainerNo = makeActionCreator(SET_TAPPED_EXPORT_CONTAINER_NO, 'tappedItemNo');

export const SET_EXPORT_DETAIL_FILTER = 'SET_EXPORT_DETAIL_FILTER';
export const setExportDetailFilter = (fieldName, value) => ({
  type: SET_EXPORT_DETAIL_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_EXPORT_DETAIL_PAGING = 'SET_EXPORT_DETAIL_PAGING';
export const setExportDetailPaging = (pageField, value) => ({
  type: SET_EXPORT_DETAIL_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_EXPORT_DETAIL_SORT = 'SET_EXPORT_DETAIL_SORT';
export const setExportDetailSort = (sortField, sortDirection) => ({
  type: SET_EXPORT_DETAIL_SORT,
  sortField,
  sortDirection,
});

// Book Export
export const BOOKING_EXPORT_SERVICE = 'BOOKING_EXPORT_SERVICE';
export const bookingExportService = makeActionCreator(BOOKING_EXPORT_SERVICE, 'status');

export const ADD_BOOK_EXPORT_SERVICE = 'ADD_BOOK_EXPORT_SERVICE';
export const addBookExportService = (values) => {
  validateBookExport(values);

  return {
    type: ADD_BOOK_EXPORT_SERVICE,
    bookExportServiceInfo: values,
  };
};

export const DOWNLOADING_DO_LIST = 'DOWNLOADING_DO_LIST';
export const downloadingDoList = makeActionCreator(DOWNLOADING_DO_LIST, 'status');

export const SET_EXPORT_DO_LIST = 'SET_EXPORT_DO_LIST';
export const setExportDoList = makeActionCreator(SET_EXPORT_DO_LIST, 'exportDoList');

export const REMOVE_BOOK_EXPORT_SERVICE_INFO = 'REMOVE_BOOK_EXPORT_SERVICE_INFO';
export const removeBookExportServiceinfo = makeActionCreator(REMOVE_BOOK_EXPORT_SERVICE_INFO);

export const SEARCHING_VESSEL_LIST = 'SEARCHING_VESSEL_LIST';
export const searchingVesselList = makeActionCreator(SEARCHING_VESSEL_LIST, 'status');

export const SEARCHING_VOYAGE_LIST = 'SEARCHING_VOYAGE_LIST';
export const searchingVoyageList = makeActionCreator(SEARCHING_VOYAGE_LIST, 'status');

export const ADD_VOYAGE_DETAIL = 'ADD_VOYAGE_DETAIL';
export const addVoyageDetail = makeActionCreator(ADD_VOYAGE_DETAIL, 'voyageDetail');

export const CLEAR_VOYAGE_DETAIL = 'CLEAR_VOYAGE_DETAIL';
export const clearVoyageDetail = () => ({
  type: CLEAR_VOYAGE_DETAIL,
});

export const ADD_VESSEL_LIST = 'ADD_VESSEL_LIST';
export const addVesselList = makeActionCreator(ADD_VESSEL_LIST, 'vesselList');

export const ADD_VOYAGE_LIST = 'ADD_VOYAGE_LIST';
export const addVoyageList = makeActionCreator(ADD_VOYAGE_LIST, 'voyageList');

export const CLEAR_VOYAGE_LIST = 'CLEAR_VOYAGE_LIST';
export const clearVoyageList = makeActionCreator(CLEAR_VOYAGE_LIST);

export const SET_VOYAGE_PICKER = 'SET_VOYAGE_PICKER';
export const setVoyagePicker = makeActionCreator(SET_VOYAGE_PICKER, 'status');

export const ADD_SELECTED_BOOK_EXPORT_VESSEL = 'ADD_SELECTED_BOOK_EXPORT_VESSEL';
export const addSelectedBookExportVessel = makeActionCreator(ADD_SELECTED_BOOK_EXPORT_VESSEL, 'selectedVessel');

export const ADD_SELECTED_BOOK_EXPORT_VOYAGE = 'ADD_SELECTED_BOOK_EXPORT_VOYAGE';
export const addSelectedBookExportVoyage = makeActionCreator(ADD_SELECTED_BOOK_EXPORT_VOYAGE, 'selectedVoyage');

export const SET_BOOK_EXPORT_ACTIVE_FORM = 'SET_BOOK_EXPORT_ACTIVE_FORM';
export const setBookExportActiveForm = value => ({
  type: SET_BOOK_EXPORT_ACTIVE_FORM,
  value,
});

export const SET_NEW_BOOKING_EXPORT_DIALOG_STATUS = 'SET_NEW_BOOKING_EXPORT_DIALOG_STATUS';
export const setBookExportDialogStatus = value => ({
  type: SET_NEW_BOOKING_EXPORT_DIALOG_STATUS,
  value,
});

export const SET_EXPORT_DETAIL_DIALOG_STATUS = 'SET_EXPORT_DETAIL_DIALOG_STATUS';
export const setExportDetailDialogStatus = value => ({
  type: SET_EXPORT_DETAIL_DIALOG_STATUS,
  value,
});

// Book Export Party
export const ADD_EXPORT_BOOK_NO = 'ADD_EXPORT_BOOK_NO';
export const addExportBookNo = makeActionCreator(ADD_EXPORT_BOOK_NO, 'bookNo');

export const SET_EXPORT_EDIT_PARTY = 'SET_EXPORT_EDIT_PARTY';
export const setExportEditParty = makeActionCreator(SET_EXPORT_EDIT_PARTY, 'value');

export const REMOVE_EXPORT_EDIT_PARTY = 'REMOVE_EXPORT_EDIT_PARTY';
export const removeExportEditParty = makeActionCreator(REMOVE_EXPORT_EDIT_PARTY, 'value');

export const MODIFY_EXPORT_BOOK_PARTY = 'MODIFY_EXPORT_BOOK_PARTY';
export const modifyExportBookParty = makeActionCreator(MODIFY_EXPORT_BOOK_PARTY, 'partyId', 'info');

export const ADD_EXPORT_BOOK_PARTY = 'ADD_EXPORT_BOOK_PARTY';
export const addExportBookParty = makeActionCreator(ADD_EXPORT_BOOK_PARTY, 'info');

export const REMOVE_EXPORT_BOOK_PARTY = 'REMOVE_EXPORT_BOOK_PARTY';
export const removeExportBookParty = makeActionCreator(REMOVE_EXPORT_BOOK_PARTY, 'partyId');

export const SET_EXPORT_NEW_PARTY = 'SET_EXPORT_NEW_PARTY';
export const setExportNewParty = makeActionCreator(SET_EXPORT_NEW_PARTY, 'partyId');

export const REMOVE_EXPORT_NEW_PARTY = 'REMOVE_EXPORT_NEW_PARTY';
export const removeExportNewParty = makeActionCreator(REMOVE_EXPORT_NEW_PARTY, 'partyId');

export const UPLOADING_BOOK_EXPORT_PARTY_DOCUMENT = 'UPLOADING_BOOK_EXPORT_PARTY_DOCUMENT';
export const uploadingBookExportPartyDocument = makeActionCreator(UPLOADING_BOOK_EXPORT_PARTY_DOCUMENT, 'status');

export const ADD_EXPORT_BOOK_PRICE_INFO = 'ADD_EXPORT_BOOK_PRICE_INFO';
export const addExportBookPriceInfo = makeActionCreator(ADD_EXPORT_BOOK_PRICE_INFO, 'priceInfo');


export const SET_DIALOG_PAYMENT_DOKU_STATUS = 'SET_DIALOG_PAYMENT_DOKU_STATUS';
export const setDialogPaymentDokuStatus = makeActionCreator(SET_DIALOG_PAYMENT_DOKU_STATUS, 'value');

export const SET_DIALOG_CONFIRM_CLOSE_PAYMENT_DOKU_STATUS = 'SET_DIALOG_CONFIRM_CLOSE_PAYMENT_DOKU_STATUS';
export const setDialogConfirmClosePaymentDokuStatus = makeActionCreator(SET_DIALOG_CONFIRM_CLOSE_PAYMENT_DOKU_STATUS, 'value');

// My Payment
export const SET_MY_PAYMENT_SELECTED_TAB = 'SET_MY_PAYMENT_SELECTED_TAB';
export const setMyPaymentSelectedTab = value => ({
  type: SET_MY_PAYMENT_SELECTED_TAB,
  value,
});

export const ADD_MY_PAYMENT_ORDER = 'ADD_MY_PAYMENT_ORDER';
export const addMyPaymentOrder = makeActionCreator(ADD_MY_PAYMENT_ORDER, 'myOrder');

export const DOWNLOADING_MY_PAYMENT_ORDER = 'DOWNLOADING_MY_PAYMENT_ORDER';
export const downloadingMyPaymentOrder = makeActionCreator(DOWNLOADING_MY_PAYMENT_ORDER, 'status');

// History
export const SET_MY_PAYMENT_HISTORY_SEARCH_TEXT = 'SET_MY_PAYMENT_HISTORY_SEARCH_TEXT';
export const setMyPaymentHistorySearchText = makeActionCreator(SET_MY_PAYMENT_HISTORY_SEARCH_TEXT, 'text');

export const SET_TAPPED_PAYMENT_HISTORY_PROFORMA_NO = 'SET_TAPPED_PAYMENT_HISTORY_PROFORMA_NO';
export const setTappedPaymentHistoryProformaNo = makeActionCreator(SET_TAPPED_PAYMENT_HISTORY_PROFORMA_NO, 'tappedProformaNo');

export const SET_MY_PAYMENT_HISTORY_FILTER = 'SET_MY_PAYMENT_HISTORY_FILTER';
export const setMyPaymentHistoryFilter = (fieldName, value) => ({
  type: SET_MY_PAYMENT_HISTORY_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MY_PAYMENT_HISTORY_PAGING = 'SET_MY_PAYMENT_HISTORY_PAGING';
export const setMyPaymentHistoryPaging = (pageField, value) => ({
  type: SET_MY_PAYMENT_HISTORY_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MY_PAYMENT_HISTORY_SORT = 'SET_MY_PAYMENT_HISTORY_SORT';
export const setMyPaymentHistorySort = (sortField, sortDirection) => ({
  type: SET_MY_PAYMENT_HISTORY_SORT,
  sortField,
  sortDirection,
});

export const SET_MY_PAYMENT_HISTORY_INFO_DIALOG_VISIBILITY = 'SET_MY_PAYMENT_HISTORY_INFO_DIALOG_VISIBILITY';
export const setMyPaymentHistoryInfoDialogVisibility = makeActionCreator(SET_MY_PAYMENT_HISTORY_INFO_DIALOG_VISIBILITY, 'status', 'data');

// Term
export const SET_MY_PAYMENT_TERM_SEARCH_TEXT = 'SET_MY_PAYMENT_TERM_SEARCH_TEXT';
export const setMyPaymentTermSearchText = makeActionCreator(SET_MY_PAYMENT_TERM_SEARCH_TEXT, 'text');

export const SET_TAPPED_TERM_BL_NO = 'SET_TAPPED_TERM_BL_NO';
export const setTappedTermBlNo = makeActionCreator(SET_TAPPED_TERM_BL_NO, 'tappedBlNo');

export const ADD_MY_PAYMENT_TERM_ORDER = 'ADD_MY_PAYMENT_TERM_ORDER';
export const addMyPaymentTermOrder = makeActionCreator(ADD_MY_PAYMENT_TERM_ORDER, 'myTermOrder');

export const ADD_DOWNLOADED_MY_PAYMENT_TERM_ORDER = 'ADD_DOWNLOADED_MY_PAYMENT_TERM_ORDER';
export const addDownloadedMyPaymentTermOrder = makeActionCreator(ADD_DOWNLOADED_MY_PAYMENT_TERM_ORDER, 'myTermOrder');

export const DOWNLOADING_MY_PAYMENT_TERM_ORDER = 'DOWNLOADING_MY_PAYMENT_TERM_ORDER';
export const downloadingMyPaymentTermOrder = makeActionCreator(DOWNLOADING_MY_PAYMENT_TERM_ORDER, 'status');

export const REMOVE_MY_PAYMENT_TERM_ORDER_ITEM = 'REMOVE_MY_PAYMENT_TERM_ORDER_ITEM';
export const removeMyPaymentTermOrderItem = makeActionCreator(REMOVE_MY_PAYMENT_TERM_ORDER_ITEM, 'id');

export const ADDING_MY_PAYMENT_TERM_ORDER_ITEM = 'ADDING_MY_PAYMENT_TERM_ORDER_ITEM';
export const addingMyPaymentTermOrderItem = makeActionCreator(ADDING_MY_PAYMENT_TERM_ORDER_ITEM, 'status');

// Outstanding
export const ADD_MY_PAYMENT_OUTSTANDING_ORDER = 'ADD_MY_PAYMENT_OUTSTANDING_ORDER';
export const addMyPaymentOutstandingOrder = makeActionCreator(ADD_MY_PAYMENT_OUTSTANDING_ORDER, 'myOutstandingOrder');

export const DOWNLOADING_MY_PAYMENT_OUTSTANDING_ORDER = 'DOWNLOADING_MY_PAYMENT_OUTSTANDING_ORDER';
export const downloadingMyPaymentOutstandingOrder = makeActionCreator(DOWNLOADING_MY_PAYMENT_OUTSTANDING_ORDER, 'status');

export const SET_MY_PAYMENT_OUTSTANDING_SEARCH_TEXT = 'SET_MY_PAYMENT_OUTSTANDING_SEARCH_TEXT';
export const setMyPaymentOutstandingSearchText = makeActionCreator(SET_MY_PAYMENT_OUTSTANDING_SEARCH_TEXT, 'text');

export const SET_TAPPED_OUTSTANDING_ITEM_NO = 'SET_TAPPED_OUTSTANDING_ITEM_NO';
export const setTappedOutstandingItemNo = makeActionCreator(SET_TAPPED_OUTSTANDING_ITEM_NO, 'tappedItemNo');

// Outstanding Proforma Filters
export const SET_MY_PAYMENT_OUTSTANDING_FILTER = 'SET_MY_PAYMENT_OUTSTANDING_FILTER';
export const setMyPaymentOutstandingFilter = (fieldName, value) => ({
  type: SET_MY_PAYMENT_OUTSTANDING_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MY_PAYMENT_OUTSTANDING_PAGING = 'SET_MY_PAYMENT_OUTSTANDING_PAGING';
export const setMyPaymentOutstandingPaging = (pageField, value) => ({
  type: SET_MY_PAYMENT_OUTSTANDING_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MY_PAYMENT_OUTSTANDING_SORT = 'SET_MY_PAYMENT_OUTSTANDING_SORT';
export const setMyPaymentOutstandingSort = (sortField, sortDirection) => ({
  type: SET_MY_PAYMENT_OUTSTANDING_SORT,
  sortField,
  sortDirection,
});


// Assign
export const SET_ASSIGN_SEARCH_TEXT = 'SET_ASSIGN_SEARCH_TEXT';
export const setAssignSearchText = makeActionCreator(SET_ASSIGN_SEARCH_TEXT, 'text');

export const SET_TAPPED_ASSIGN_BL_NO = 'SET_TAPPED_ASSIGN_BL_NO';
export const setTappedAssignBlNo = makeActionCreator(SET_TAPPED_ASSIGN_BL_NO, 'tappedBlNo');

export const SET_TAPPED_ASSIGN_BASE_SERVICE = 'SET_TAPPED_ASSIGN_BASE_SERVICE';
export const setTappedAssignBaseService = makeActionCreator(SET_TAPPED_ASSIGN_BASE_SERVICE, 'tappedService');

export const ADD_ASSIGNED_BLS = 'ADD_ASSIGNED_BLS';
export const addAssignedBls = makeActionCreator(ADD_ASSIGNED_BLS, 'assign');

export const ADD_ASSIGNED_BL = 'ADD_ASSIGNED_BL';
export const addAssignedBl = makeActionCreator(ADD_ASSIGNED_BL, 'assign');

export const DOWNLOADING_ASSIGN = 'DOWNLOADING_ASSIGN';
export const downloadingAssign = makeActionCreator(DOWNLOADING_ASSIGN, 'status');

export const SET_ASSIGN_BASE_SEARCH_TEXT = 'SET_ASSIGN_BASE_SEARCH_TEXT';
export const setAssignBaseSearchText = makeActionCreator(SET_ASSIGN_BASE_SEARCH_TEXT, 'text');

export const SET_ASSIGN_CLEARANCE_DIALOG_STATUS = 'SET_ASSIGN_CLEARANCE_DIALOG_STATUS';
export const setAssignClearanceDialogStatus = makeActionCreator(SET_ASSIGN_CLEARANCE_DIALOG_STATUS, 'value');

export const ADD_MULTI_SELECT_ASSIGN_BL = 'ADD_MULTI_SELECT_ASSIGN_BL';
export const addMultiSelectAssignBl = makeActionCreator(ADD_MULTI_SELECT_ASSIGN_BL, 'value');

export const REMOVE_MULTI_SELECT_ASSIGN_BL = 'REMOVE_MULTI_SELECT_ASSIGN_BL';
export const removeMultiSelectAssignBl = makeActionCreator(REMOVE_MULTI_SELECT_ASSIGN_BL, 'value');

export const SET_ASSIGN_MULTIPLE_CLEARANCE_DIALOG_STATUS = 'SET_ASSIGN_MULTIPLE_CLEARANCE_DIALOG_STATUS';
export const setAssignMutipleClearanceDialogStatus = makeActionCreator(SET_ASSIGN_MULTIPLE_CLEARANCE_DIALOG_STATUS, 'value');

// Assign - Order Type
export const ADD_ASSIGN_ORDER_TYPE = 'ADD_ASSIGN_ORDER_TYPE';
export const addAssignOrderType = makeActionCreator(ADD_ASSIGN_ORDER_TYPE, 'assignOrderType');

export const DOWNLOADING_ASSIGN_ORDER_TYPE = 'DOWNLOADING_ASSIGN_ORDER_TYPE';
export const downloadingAssignOrderType = makeActionCreator(DOWNLOADING_ASSIGN_ORDER_TYPE, 'status');

export const SET_TAPPED_ASSIGN_ORDER_TYPE_NO = 'SET_TAPPED_ASSIGN_ORDER_TYPE_NO';
export const setTappedAssignOrderTypeNo = makeActionCreator(SET_TAPPED_ASSIGN_ORDER_TYPE_NO, 'tappedBlNo', 'tappedSoNo');

export const SET_ASSIGN_CONTAINER_DIALOG_STATUS = 'SET_ASSIGN_CONTAINER_DIALOG_STATUS';
export const setAssignContainerDialogStatus = makeActionCreator(SET_ASSIGN_CONTAINER_DIALOG_STATUS, 'value');

// Assign - container
export const SET_ASSIGN_CONTAINER_ACTIVE_FORM = 'SET_ASSIGN_CONTAINER_ACTIVE_FORM';
export const setAssignContainerActiveForm = makeActionCreator(SET_ASSIGN_CONTAINER_ACTIVE_FORM, 'value');

export const ADD_ASSIGN_TRUCK = 'ADD_ASSIGN_TRUCK';
export const addAssignTruck = makeActionCreator(ADD_ASSIGN_TRUCK, 'assignTruck');

export const REMOVE_ASSIGN_TRUCK = 'REMOVE_ASSIGN_TRUCK';
export const removeAssignTruck = makeActionCreator(REMOVE_ASSIGN_TRUCK);

export const UPLOADING_ASSIGN_TRUCK = 'UPLOADING_ASSIGN_TRUCK';
export const uploadingAssignTruck = makeActionCreator(UPLOADING_ASSIGN_TRUCK, 'status');

export const UPLOADING_ASSIGN_CONTAINER_ADD = 'UPLOADING_ASSIGN_CONTAINER_ADD';
export const uploadingAssignContainerAdd = makeActionCreator(UPLOADING_ASSIGN_CONTAINER_ADD, 'status');

// Assign filters
export const SET_ASSIGN_FILTER = 'SET_ASSIGN_FILTER';
export const setAssignFilter = (fieldName, value) => ({
  type: SET_ASSIGN_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ASSIGN_PAGING = 'SET_ASSIGN_PAGING';
export const setAssignPaging = (pageField, value) => ({
  type: SET_ASSIGN_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ASSIGN_SORT = 'SET_ASSIGN_SORT';
export const setAssignSort = (sortField, sortDirection) => ({
  type: SET_ASSIGN_SORT,
  sortField,
  sortDirection,
});

// Assign Contianer filters
export const SET_ASSIGN_CONTAINER_FILTER = 'SET_ASSIGN_CONTAINER_FILTER';
export const setAssignContainerFilter = (fieldName, value) => ({
  type: SET_ASSIGN_CONTAINER_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ASSIGN_CONTAINER_PAGING = 'SET_ASSIGN_CONTAINER_PAGING';
export const setAssignContainerPaging = (pageField, value) => ({
  type: SET_ASSIGN_CONTAINER_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ASSIGN_CONTAINER_SORT = 'SET_ASSIGN_CONTAINER_SORT';
export const setAssignContainerSort = (sortField, sortDirection) => ({
  type: SET_ASSIGN_CONTAINER_SORT,
  sortField,
  sortDirection,
});

// assign container
export const ADD_ASSIGN_CONTAINER = 'ADD_ASSIGN_CONTAINER';
export const addAssignContainer = makeActionCreator(ADD_ASSIGN_CONTAINER, 'assignContainer');

export const DOWNLOADING_ASSIGN_CONTAINER = 'DOWNLOADING_ASSIGN_CONTAINER';
export const downloadingAssignContainer = makeActionCreator(DOWNLOADING_ASSIGN_CONTAINER, 'status');

export const DOWNLOADING_PPJK_LIST_BY_ASSIGN = 'DOWNLOADING_PPJK_LIST_BY_ASSIGN';
export const downloadingPpjkListByAssign = makeActionCreator(DOWNLOADING_PPJK_LIST_BY_ASSIGN, 'status');

export const ADD_PPJK_LIST_BY_ASSIGN = 'ADD_PPJK_LIST_BY_ASSIGN';
export const addPpjkListByAssign = makeActionCreator(ADD_PPJK_LIST_BY_ASSIGN, 'ppjkList');

export const REMOVE_PPJK_LIST_BY_ASSIGN = 'REMOVE_PPJK_LIST_BY_ASSIGN';
export const removePpjkListByAssign = makeActionCreator(REMOVE_PPJK_LIST_BY_ASSIGN);

export const DOWNLOADING_TRUCK_LIST = 'DOWNLOADING_TRUCK_LIST';
export const downloadingTruckList = makeActionCreator(DOWNLOADING_TRUCK_LIST, 'status');

export const ADD_TRUCK_LIST = 'ADD_TRUCK_LIST';
export const addTruckList = makeActionCreator(ADD_TRUCK_LIST, 'truckList');

export const REMOVE_TRUCK_LIST = 'REMOVE_TRUCK_LIST';
export const removeTruckList = makeActionCreator(REMOVE_TRUCK_LIST);

export const SEARCHING_ASSIGN_TRUCK_VENDOR_LIST = 'SEARCHING_ASSIGN_TRUCK_VENDOR_LIST';
export const searchingAssignTruckVendorList = makeActionCreator(SEARCHING_ASSIGN_TRUCK_VENDOR_LIST, 'status');

export const SEARCHING_TRUCK_LIST = 'SEARCHING_TRUCK_LIST';
export const searchingTruckList = makeActionCreator(SEARCHING_TRUCK_LIST, 'status');

export const SET_DIALOG_ASSIGN_ADD_CONTAINER_STATUS = 'SET_DIALOG_ASSIGN_ADD_CONTAINER_STATUS';
export const setDialogAssignAddContainerStatus = makeActionCreator(SET_DIALOG_ASSIGN_ADD_CONTAINER_STATUS, 'value');

export const ADD_ASSIGN_CONTAINER_ADD = 'ADD_ASSIGN_CONTAINER_ADD';
export const addAssignContainerAdd = makeActionCreator(ADD_ASSIGN_CONTAINER_ADD, 'assignContainerAdd');

export const REMOVE_ASSIGN_CONTAINER_ADD = 'REMOVE_ASSIGN_CONTAINER_ADD';
export const removeAssignContainerAdd = makeActionCreator(REMOVE_ASSIGN_CONTAINER_ADD);

// assign clearance
export const ADD_ASSIGN_CLEARANCE = 'ADD_ASSIGN_CLEARANCE';
export const addAssignClearance = makeActionCreator(ADD_ASSIGN_CLEARANCE, 'assignClearance');

export const REMOVE_ASSIGN_CLEARANCE = 'REMOVE_ASSIGN_CLEARANCE';
export const removeAssignClearance = makeActionCreator(REMOVE_ASSIGN_CLEARANCE);

export const UPLOADING_ASSIGN_CLEARANCE = 'UPLOADING_ASSIGN_CLEARANCE';
export const uploadingAssignClearance = makeActionCreator(UPLOADING_ASSIGN_CLEARANCE, 'status');

export const ADD_ASSIGN_MULTIPLE_CLEARANCE = 'ADD_ASSIGN_MULTIPLE_CLEARANCE';
export const addAssignMultipleClearance = makeActionCreator(ADD_ASSIGN_MULTIPLE_CLEARANCE, 'assignClearance');

export const REMOVE_ASSIGN_MULTIPLE_CLEARANCE = 'REMOVE_ASSIGN_MULTIPLE_CLEARANCE';
export const removeAssignMultipleClearance = makeActionCreator(REMOVE_ASSIGN_MULTIPLE_CLEARANCE);

// dashboard
export const SET_SHOW_ALL_MENU = 'SET_SHOW_ALL_MENU';
export const setShowAllMenu = makeActionCreator(SET_SHOW_ALL_MENU, 'value');

// Master User
export const DOWNLOADING_MASTER_USER = 'DOWNLOADING_MASTER_USER';
export const downloadingMasterUser = makeActionCreator(DOWNLOADING_MASTER_USER, 'value');

export const UPLOADING_MASTER_USER = 'UPLOADING_MASTER_USER';
export const uploadingMasterUser = makeActionCreator(UPLOADING_MASTER_USER, 'value');

export const SET_MASTER_USER_SELECTED_ID = 'SET_MASTER_USER_SELECTED_ID';
export const setMasterUserSelectedId = makeActionCreator(SET_MASTER_USER_SELECTED_ID, 'value');

export const SET_MASTER_USER_EDIT_DIALOG_STATUS = 'SET_MASTER_USER_EDIT_DIALOG_STATUS';
export const setMasterUserEditDialogStatus = makeActionCreator(SET_MASTER_USER_EDIT_DIALOG_STATUS, 'value');

export const SET_MASTER_USER_ADD_DIALOG_STATUS = 'SET_MASTER_USER_ADD_DIALOG_STATUS';
export const setMasterUserAddDialogStatus = makeActionCreator(SET_MASTER_USER_ADD_DIALOG_STATUS, 'value');

export const SET_MASTER_USER_FILTER = 'MASTER_USER_FILTER';
export const setMasterUserFilter = (fieldName, value) => ({
  type: SET_MASTER_USER_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_MASTER_USER_PAGING = 'SET_MASTER_USER_PAGING';
export const setMasterUserPaging = (pageField, value) => ({
  type: SET_MASTER_USER_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_MASTER_USER_SORT = 'SET_MASTER_USER_SORT';
export const setMasterUserSort = (sortField, sortDirection) => ({
  type: SET_MASTER_USER_SORT,
  sortField,
  sortDirection,
});

// form
export const DOWNLOADING_FORM = 'DOWNLOADING_FORM';
export const downloadingForm = makeActionCreator(DOWNLOADING_FORM, 'value');


export const SET_FORMS = 'SET_FORMS';
export const setForms = makeActionCreator(SET_FORMS, 'data');

export const ADD_FORM = 'ADD_FORM';
export const addForm = makeActionCreator(ADD_FORM, 'data');

export const CLEAR_FORMS = 'CLEAR_FORMS';
export const clearForms = makeActionCreator(CLEAR_FORMS);

export const UPLOADING_FORM = 'DOWNLOADING_FORM';
export const uploadingForm = makeActionCreator(UPLOADING_FORM, 'value');

export const DELETING_FORM = 'DELETING_FORM';
export const deletingForm = makeActionCreator(DELETING_FORM, 'value');

export const SET_FORM_SELECTED_ID = 'SET_FORM_SELECTED_ID';
export const setFormSelectedId = makeActionCreator(SET_FORM_SELECTED_ID, 'value');

export const SET_FORM_EDIT_DIALOG_STATUS = 'SET_FORM_EDIT_DIALOG_STATUS';
export const setFormEditDialogStatus = makeActionCreator(SET_FORM_EDIT_DIALOG_STATUS, 'value');

export const SET_FORM_ADD_DIALOG_STATUS = 'SET_FORM_ADD_DIALOG_STATUS';
export const setFormAddDialogStatus = makeActionCreator(SET_FORM_ADD_DIALOG_STATUS, 'value');

export const SET_FORM_DETAIL_DIALOG_STATUS = 'SET_FORM_DETAIL_DIALOG_STATUS';
export const setFormDetailDialogStatus = makeActionCreator(SET_FORM_DETAIL_DIALOG_STATUS, 'value');

export const SET_FORM_DELETE_DIALOG_STATUS = 'SET_FORM_DELETE_DIALOG_STATUS';
export const setFormDeleteDialogStatus = makeActionCreator(SET_FORM_DELETE_DIALOG_STATUS, 'value');

export const SET_FORM_FILTER = 'FORM_FILTER';
export const setFormFilter = (fieldName, value) => ({
  type: SET_FORM_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_FORM_PAGING = 'SET_FORM_PAGING';
export const setFormPaging = (pageField, value) => ({
  type: SET_FORM_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_FORM_SORT = 'SET_FORM_SORT';
export const setFormSort = (sortField, sortDirection) => ({
  type: SET_FORM_SORT,
  sortField,
  sortDirection,
});

// form
export const DOWNLOADING_FIELD = 'DOWNLOADING_FIELD';
export const downloadingField = makeActionCreator(DOWNLOADING_FIELD, 'value');

export const SET_FIELDS = 'SET_FIELDS';
export const setFields = makeActionCreator(SET_FIELDS, 'data');

export const ADD_FIELD = 'ADD_FIELD';
export const addField = makeActionCreator(ADD_FIELD, 'data');

export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const clearFields = makeActionCreator(CLEAR_FIELDS);

export const UPLOADING_FIELD = 'DOWNLOADING_FIELD';
export const uploadingField = makeActionCreator(UPLOADING_FIELD, 'value');

export const DELETING_FIELD = 'DELETING_FIELD';
export const deletingField = makeActionCreator(DELETING_FIELD, 'value');

export const SET_FIELD_SELECTED_ID = 'SET_FIELD_SELECTED_ID';
export const setFieldSelectedId = makeActionCreator(SET_FIELD_SELECTED_ID, 'value');

export const SET_FIELD_EDIT_DIALOG_STATUS = 'SET_FIELD_EDIT_DIALOG_STATUS';
export const setFieldEditDialogStatus = makeActionCreator(SET_FIELD_EDIT_DIALOG_STATUS, 'value');

export const SET_FIELD_ADD_DIALOG_STATUS = 'SET_FIELD_ADD_DIALOG_STATUS';
export const setFieldAddDialogStatus = makeActionCreator(SET_FIELD_ADD_DIALOG_STATUS, 'value');

export const SET_FIELD_DETAIL_DIALOG_STATUS = 'SET_FIELD_DETAIL_DIALOG_STATUS';
export const setFieldDetailDialogStatus = makeActionCreator(SET_FIELD_DETAIL_DIALOG_STATUS, 'value');

export const SET_FIELD_DELETE_DIALOG_STATUS = 'SET_FIELD_DELETE_DIALOG_STATUS';
export const setFieldDeleteDialogStatus = makeActionCreator(SET_FIELD_DELETE_DIALOG_STATUS, 'value');

export const SET_FIELD_FILTER_DIALOG_STATUS = 'SET_FIELD_FILTER_DIALOG_STATUS';
export const setFieldFilterDialogStatus = makeActionCreator(SET_FIELD_FILTER_DIALOG_STATUS, 'value');

export const SET_TEMP_FILTER_FORM = 'SET_TEMP_FILTER_FORM';
export const setTempFilterForm = makeActionCreator(SET_TEMP_FILTER_FORM, 'value');

export const SET_FIELD_FILTER = 'FIELD_FILTER';
export const setFieldFilter = (fieldName, value) => ({
  type: SET_FIELD_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_FIELD_PAGING = 'SET_FIELD_PAGING';
export const setFieldPaging = (pageField, value) => ({
  type: SET_FIELD_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_FIELD_SORT = 'SET_FIELD_SORT';
export const setFieldSort = (sortField, sortDirection) => ({
  type: SET_FIELD_SORT,
  sortField,
  sortDirection,
});

export const SET_TEMP_SELECTED_FORM_FILTER = 'SET_TEMP_SELECTED_FORM_FILTER';
export const setTempSelectedFormFilter = makeActionCreator(SET_TEMP_SELECTED_FORM_FILTER, 'value');

// Notification
export const DOWNLOADING_MY_NOTIFICATION = 'DOWNLOADING_MY_NOTIFICATION';
export const downloadingMyNotification = makeActionCreator(DOWNLOADING_MY_NOTIFICATION, 'value');

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const addNotification = makeActionCreator(ADD_NOTIFICATION, 'value');

export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';
export const setNotificationAsRead = makeActionCreator(SET_NOTIFICATION_AS_READ, 'id');

export const UPLOADING_NOTIFICATION_READ_STATUS = 'UPLOADING_NOTIFICATION_READ_STATUS';
export const uploadingNotificationReadStatus = makeActionCreator(UPLOADING_NOTIFICATION_READ_STATUS, 'value');

export const SET_NOTIFICATION_NEXT_AUTO_UPDATE = 'SET_NOTIFICATION_NEXT_AUTO_UPDATE';
export const setNotificationNextAutoUpdate = makeActionCreator(SET_NOTIFICATION_NEXT_AUTO_UPDATE, 'value');


// pdf
export const REQUESTING_PRINT = 'REQUESTING_PRINT';
export const requestingPrint = makeActionCreator(REQUESTING_PRINT, 'status', 'id', 'printType');

export const SET_TAPPED_PDF_VIEWER_TITLE = 'SET_TAPPED_PDF_VIEWER_TITLE';
export const setTappedPdfViewerTitle = makeActionCreator(SET_TAPPED_PDF_VIEWER_TITLE, 'tappedTitle');

export const SET_PDF_VIEWER_SOURCE_URL = 'SET_PDF_VIEWER_SOURCE_URL';
export const setPdfViewerSourceUrl = makeActionCreator(SET_PDF_VIEWER_SOURCE_URL, 'url');

export const SET_PDF_VIEWER_STATUS = 'SET_PDF_VIEWER_STATUS';
export const setPdfViewerStatus = makeActionCreator(SET_PDF_VIEWER_STATUS, 'value');


// deposit history
export const ADD_DEPOSIT_HISTORY = 'ADD_DEPOSIT_HISTORY';
export const addDepositHistory = makeActionCreator(ADD_DEPOSIT_HISTORY, 'depositHistory');

export const DOWNLOADING_DEPOSIT_HISTORY = 'DOWNLOADING_DEPOSIT_HISTORY';
export const downloadingDepositHistory = makeActionCreator(DOWNLOADING_DEPOSIT_HISTORY, 'status');

export const SET_TAPPED_DEPOSIT_HISTORY_ID = 'SET_TAPPED_DEPOSIT_HISTORY_ID';
export const setTappedDepositHistoryId = makeActionCreator(SET_TAPPED_DEPOSIT_HISTORY_ID, 'tappedId');

export const SET_TOP_UP_DIALOG_STATUS = 'SET_TOP_UP_DIALOG_STATUS';
export const setTopUpDialogStatus = makeActionCreator(SET_TOP_UP_DIALOG_STATUS, 'status');

export const SET_DEPOSIT_HISTORY_FILTER = 'DEPOSIT_HISTORY_FILTER';
export const setDepositHistoryFilter = (fieldName, value) => ({
  type: SET_DEPOSIT_HISTORY_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_DEPOSIT_HISTORY_VALIDATION_MESSAGES = 'SET_DEPOSIT_HISTORY_VALIDATION_MESSAGES';
export const setDepositHistoryValidationMessages = (fieldName, message) => ({
  type: SET_DEPOSIT_HISTORY_VALIDATION_MESSAGES,
  value: {
    [fieldName]: message,
  },
});

export const SET_DEPOSIT_HISTORY_PAGING = 'SET_DEPOSIT_HISTORY_PAGING';
export const setDepositHistoryPaging = (pageField, value) => ({
  type: SET_DEPOSIT_HISTORY_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_DEPOSIT_HISTORY_SORT = 'SET_DEPOSIT_HISTORY_SORT';
export const setDepositHistorySort = (sortField, sortDirection) => ({
  type: SET_DEPOSIT_HISTORY_SORT,
  sortField,
  sortDirection,
});


export const SUBMITTING_REGISTER_PASSWORD = 'SUBMITTING_REGISTER_PASSWORD';
export const submittingRegisterPassword = makeActionCreator(SUBMITTING_REGISTER_PASSWORD, 'status');

export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
export const setRememberMe = value => ({ type: SET_REMEMBER_ME, value });


export const ADD_EXPORT_DG_CLASS_LIST = 'ADD_EXPORT_DG_CLASS_LIST';
export const addExportDGClassList = makeActionCreator(ADD_EXPORT_DG_CLASS_LIST, 'dgClassList');

export const DOWNLOADING_EXPORT_DG_CLASS_LIST = 'DOWNLOADING_EXPORT_DG_CLASS_LIST';
export const downloadingExportDGClassList = makeActionCreator(DOWNLOADING_EXPORT_DG_CLASS_LIST, 'status');

export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING';
export const setGlobalLoading = makeActionCreator(SET_GLOBAL_LOADING, 'status');


// Admin Payment History
export const CLEAR_ADMIN_PAYMENT_HISTORY_DATA = 'CLEAR_ADMIN_PAYMENT_HISTORY_DATA';
export const clearAdminPaymentHistoryData = makeActionCreator(CLEAR_ADMIN_PAYMENT_HISTORY_DATA);

export const ADD_ADMIN_PAYMENT_HISTORY_DATA = 'ADD_ADMIN_PAYMENT_HISTORY_DATA';
export const addAdminPaymentHistoryData = makeActionCreator(ADD_ADMIN_PAYMENT_HISTORY_DATA, 'dataAsObj', 'data', 'totalData');

export const SET_ADMIN_PAYMENT_HISTORY_DIALOG_ADD_STATUS = 'SET_ADMIN_PAYMENT_HISTORY_DIALOG_ADD_STATUS';
export const setAdminPaymentHistoryDialogAddStatus = status => ({
  type: SET_ADMIN_PAYMENT_HISTORY_DIALOG_ADD_STATUS,
  status,
});

export const SET_ADMIN_PAYMENT_HISTORY_DIALOG_DETAIL_STATUS = 'SET_ADMIN_PAYMENT_HISTORY_DIALOG_DETAIL_STATUS';
export const setAdminPaymentHistoryDialogDetailStatus = status => ({
  type: SET_ADMIN_PAYMENT_HISTORY_DIALOG_DETAIL_STATUS,
  status,
});

export const SET_ADMIN_PAYMENT_HISTORY_FILTER = 'ADMIN_PAYMENT_HISTORY_FILTER';
export const setAdminPaymentHistoryFilter = (fieldName, value) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ADMIN_PAYMENT_HISTORY_PAGING = 'SET_ADMIN_PAYMENT_HISTORY_PAGING';
export const setAdminPaymentHistoryPaging = (pageField, value) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ADMIN_PAYMENT_HISTORY_SORT = 'SET_ADMIN_PAYMENT_HISTORY_SORT';
export const setAdminPaymentHistorySort = (sortField, sortDirection) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_SORT,
  sortField,
  sortDirection,
});

export const DOWNLOADING_ADMIN_PAYMENT_HISTORY_STATUS = 'DOWNLOADING_ADMIN_PAYMENT_HISTORY_STATUS';
export const downloadingAdminPaymentHistoryStatus = status => ({
  type: DOWNLOADING_ADMIN_PAYMENT_HISTORY_STATUS,
  status,
});

export const SET_TAPPED_ADMIN_PAYMENT_HISTORY_ITEM_NO = 'SET_TAPPED_ADMIN_PAYMENT_HISTORY_ITEM_NO';
export const setTappedAdminPaymentHistoryItemNo = tappedItemNo => ({
  type: SET_TAPPED_ADMIN_PAYMENT_HISTORY_ITEM_NO,
  value: tappedItemNo,
});


export const SET_ADMIN_PAYMENT_HISTORY_DETAIL_FILTER = 'SET_ADMIN_PAYMENT_HISTORY_DETAIL_FILTER';
export const setAdminPaymentHistoryDetailFilter = (fieldName, value) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_DETAIL_FILTER,
  value: {
    [fieldName]: value,
  },
});

export const SET_ADMIN_PAYMENT_HISTORY_DETAIL_PAGING = 'SET_ADMIN_PAYMENT_HISTORY_DETAIL_PAGING';
export const setAdminPaymentHistoryDetailPaging = (pageField, value) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_DETAIL_PAGING,
  value: {
    [pageField]: value,
  },
});

export const SET_ADMIN_PAYMENT_HISTORY_DETAIL_SORT = 'SET_ADMIN_PAYMENT_HISTORY_DETAIL_SORT';
export const setAdminPaymentHistoryDetailSort = (sortField, sortDirection) => ({
  type: SET_ADMIN_PAYMENT_HISTORY_DETAIL_SORT,
  sortField,
  sortDirection,
});

export const CLEAR_ADMIN_PAYMENT_HISTORY_DETAIL_STATE = 'CLEAR_ADMIN_PAYMENT_HISTORY_DETAIL_STATE';
export const clearAdminPaymentHistoryDetailState = () => ({
  type: CLEAR_ADMIN_PAYMENT_HISTORY_DETAIL_STATE,
});

export const SET_ADMIN_PAYMENT_HISTORY_SEARCH_TEXT = 'SET_ADMIN_PAYMENT_HISTORY_SEARCH_TEXT';
export const setAdminPaymentHistorySearchText = value => ({
  type: SET_ADMIN_PAYMENT_HISTORY_SEARCH_TEXT,
  value,
});

export const UPLOADING_ADMIN_PAYMENT = 'UPLOADING_ADMIN_PAYMENT';
export const uploadingAdminPayment = status => ({
  type: UPLOADING_ADMIN_PAYMENT,
  status,
});

export const DOWNLOADING_ADMIN_PAYABLE_BL = 'DOWNLOADING_ADMIN_PAYABLE_BL';
export const downloadingAdminPayableBl = status => ({
  type: DOWNLOADING_ADMIN_PAYABLE_BL,
  status,
});

export const SET_ADMIN_PAYABLE_BL = 'SET_ADMIN_PAYABLE_BL';
export const setAdminPayableBl = bls => ({
  type: SET_ADMIN_PAYABLE_BL,
  value: bls,
});

export const CLEAR_ADMIN_PAYABLE_BL = 'CLEAR_ADMIN_PAYABLE_BL';
export const clearAdminPayableBl = () => ({
  type: CLEAR_ADMIN_PAYABLE_BL,
});

export const DOWNLOADING_ADMIN_PAYABLE_BL_PAYMENT_OWNER = 'DOWNLOADING_ADMIN_PAYABLE_BL_PAYMENT_OWNER';
export const downloadingAdminPayableBlPaymentOwner = status => ({
  type: DOWNLOADING_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
  status,
});

export const SET_ADMIN_PAYABLE_BL_PAYMENT_OWNER = 'SET_ADMIN_PAYABLE_BL_PAYMENT_OWNER';
export const setAdminPayableBlPaymentOwner = value => ({
  type: SET_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
  value,
});

export const CLEAR_ADMIN_PAYABLE_BL_PAYMENT_OWNER = 'CLEAR_ADMIN_PAYABLE_BL_PAYMENT_OWNER';
export const clearAdminPayableBlPaymentOwner = () => ({
  type: CLEAR_ADMIN_PAYABLE_BL_PAYMENT_OWNER,
});

export const ADD_ADMIN_PAYMENT_DATA = 'ADD_ADMIN_PAYMENT_DATA';
export const addAdminPaymentData = value => ({
  type: ADD_ADMIN_PAYMENT_DATA,
  value,
});

export const CLEAR_ADMIN_PAYMENT_DATA = 'CLEAR_ADMIN_PAYMENT_DATA';
export const clearAdminPaymentData = () => ({ type: CLEAR_ADMIN_PAYMENT_DATA });

export const SET_DIALOG_ADMIN_PAYMENT_PIN_INPUT_STATUS = 'SET_DIALOG_ADMIN_PAYMENT_PIN_INPUT_STATUS';
export const setDialogAdminPaymentPinInputStatus = status => ({
  type: SET_DIALOG_ADMIN_PAYMENT_PIN_INPUT_STATUS,
  status,
});

export const SET_ADMIN_PAYMENT_PIN_TEXT = 'SET_ADMIN_PAYMENT_PIN_TEXT';
export const setAdminPaymentPinText = value => ({
  type: SET_ADMIN_PAYMENT_PIN_TEXT,
  value,
});

export const SET_ADMIN_PAYMENT_DOWNLOAD_LIMIT_OPTION = 'SET_ADMIN_PAYMENT_DOWNLOAD_LIMIT_OPTION';
export const setAdminPaymentDownloadLimitOption = value => ({
  type: SET_ADMIN_PAYMENT_DOWNLOAD_LIMIT_OPTION,
  value,
});

export const SET_DIALOG_ADMIN_PAYMENT_SUCCESS_STATUS = 'SET_DIALOG_ADMIN_PAYMENT_SUCCESS_STATUS';
export const setDialogAdminPaymentSuccessStatus = status => ({
  type: SET_DIALOG_ADMIN_PAYMENT_SUCCESS_STATUS,
  status,
});

export const SET_ADMIN_PAYMENT_RESULT = 'SET_ADMIN_PAYMENT_RESULT';
export const setAdminPaymentResult = value => ({
  type: SET_ADMIN_PAYMENT_RESULT,
  value,
});

export const ADD_SHOPPING_CART_ADMIN_PAYMENT = 'ADD_SHOPPING_CART_ADMIN_PAYMENT';
export const addShoppingCartAdminPayment = value => ({
  type: ADD_SHOPPING_CART_ADMIN_PAYMENT,
  value,
});

export const REMOVE_SHOPPING_CART_ADMIN_PAYMENT = 'REMOVE_SHOPPING_CART_ADMIN_PAYMENT';
export const removeShoppingCartAdminPayment = invoiceNo => ({
  type: REMOVE_SHOPPING_CART_ADMIN_PAYMENT,
  value: invoiceNo,
});

export const CLEAR_SHOPPING_CART_ADMIN_PAYMENT = 'CLEAR_SHOPPING_CART_ADMIN_PAYMENT';
export const clearShoppingCartAdminPayment = () => ({ type: CLEAR_SHOPPING_CART_ADMIN_PAYMENT });

export const ADD_EXPORT_PRICE_DETAIL = 'ADD_EXPORT_PRICE_DETAIL';
export const addExportPriceDetail = value => ({
  type: ADD_EXPORT_PRICE_DETAIL,
  value,
});

export const DOWNLOADING_EXPORT_PRICE_DETAIL = 'DOWNLOADING_EXPORT_PRICE_DETAIL';
export const downloadingExportPriceDetail = value => ({
  type: DOWNLOADING_EXPORT_PRICE_DETAIL,
  value,
});

export const ADD_FEEDER_SERVICE_PRICE_DETAIL = 'ADD_FEEDER_SERVICE_PRICE_DETAIL';
export const addFeederServicePriceDetail = value => ({
  type: ADD_FEEDER_SERVICE_PRICE_DETAIL,
  value,
});

export const DOWNLOADING_FEEDER_SERVICE_PRICE_DETAIL = 'DOWNLOADING_FEEDER_SERVICE_PRICE_DETAIL';
export const downloadingFeederServicePriceDetail = value => ({
  type: DOWNLOADING_FEEDER_SERVICE_PRICE_DETAIL,
  value,
});


export const SET_CANCELLING_OUTSTANDING_ORDER_STATUS = 'SET_CANCELLING_OUTSTANDING_ORDER_STATUS';
export const setCancellingOutstandingOrderStatus = status => ({
  type: SET_CANCELLING_OUTSTANDING_ORDER_STATUS,
  status,
});

export const SET_CHANGING_ORDER_PAYMENT_STATUS = 'SET_CHANGING_ORDER_PAYMENT_STATUS';
export const setChangingOrderPaymentStatus = status => ({
  type: SET_CHANGING_ORDER_PAYMENT_STATUS,
  status,
});


export const DOWNLOADING_TARIFF_SIMULATION_PROFORMA_INVOICE = 'DOWNLOADING_TARIFF_SIMULATION_PROFORMA_INVOICE';
export const downloadingTariffSimulationProformaInvoice = value => ({
  type: DOWNLOADING_TARIFF_SIMULATION_PROFORMA_INVOICE,
  value,
});


export const SET_TARIFF_SIMULATION_RELEASE_SEARCH_SEARCH_TEXT = 'SET_TARIFF_SIMULATION_RELEASE_SEARCH_SEARCH_TEXT';
export const setTariffSimulationReleaseSearchSearchText = value => ({
  type: SET_TARIFF_SIMULATION_RELEASE_SEARCH_SEARCH_TEXT,
  text: value,
});

export const DOWNLOADING_TARIFF_SIMULATION_RELEASE_SEARCH = 'DOWNLOADING_TARIFF_SIMULATION_RELEASE_SEARCH';
export const downloadingTariffSimulationReleaseSearch = value => ({
  type: DOWNLOADING_TARIFF_SIMULATION_RELEASE_SEARCH,
  value,
});

export const SET_TARIFF_SIMULATION_RELEASE_SEARCH_TEXT = 'SET_TARIFF_SIMULATION_RELEASE_SEARCH_TEXT';
export const setTariffSimulationReleaseSearchText = value => ({
  type: SET_TARIFF_SIMULATION_RELEASE_SEARCH_TEXT,
  text: value,
});

export const ADD_TARIFF_SIMULATION_RELEASE_INFO = 'ADD_TARIFF_SIMULATION_RELEASE_INFO';
export const addTariffSimulationReleaseInfo = value => ({
  type: ADD_TARIFF_SIMULATION_RELEASE_INFO,
  value,
});

export const ADD_TARIFF_SIMULATION_RELEASE_ORDER = 'ADD_TARIFF_SIMULATION_RELEASE_ORDER';
export const addTariffSimulationReleaseOrder = value => ({
  type: ADD_TARIFF_SIMULATION_RELEASE_ORDER,
  value,
});

export const SET_COMBINE_TARIFF_SIMULATION_ORDER_LIST_WITH_IMPORT_RELEASE_ORDER = 'SET_COMBINE_TARIFF_SIMULATION_ORDER_LIST_WITH_IMPORT_RELEASE_ORDER';
export const setCombineTariffSimulationOrderListWithImportReleaseOrder = value => ({
  type: SET_COMBINE_TARIFF_SIMULATION_ORDER_LIST_WITH_IMPORT_RELEASE_ORDER,
  value,
});

export const DOWNLOADING_MONEY_LOAN_INFO = 'DOWNLOADING_MONEY_LOAN_INFO';
export const downloadingMoneyLoanInfo = makeActionCreator(DOWNLOADING_MONEY_LOAN_INFO, 'status');

export const SET_MONEY_LOAN_INFO = 'SET_MONEY_LOAN_INFO';
export const setMoneyLoanInfo = makeActionCreator(SET_MONEY_LOAN_INFO, 'value');

export const SET_MONEY_LOAN_TNC = 'SET_MONEY_LOAN_TNC';
export const setMoneyLoanTnc = makeActionCreator(SET_MONEY_LOAN_TNC, 'text');

export const SET_MONEY_LOAN_TNC_DIALOG = 'SET_MONEY_LOAN_TNC_DIALOG';
export const setMoneyLoanTncDialog = makeActionCreator(SET_MONEY_LOAN_TNC_DIALOG, 'status');

export const DOWNLOADING_MONEY_LOAN_TNC = 'DOWNLOADING_MONEY_LOAN_TNC';
export const downloadingMoneyLoanTnc = makeActionCreator(DOWNLOADING_MONEY_LOAN_TNC, 'status');

export const SUBMITTING_MONEY_LOAN = 'SUBMITTING_MONEY_LOAN';
export const submittingMoneyLoan = makeActionCreator(SUBMITTING_MONEY_LOAN, 'status');

export const DOWNLOADING_MONEY_LOAN_HISTORY = 'DOWNLOADING_MONEY_LOAN_HISTORY';
export const downloadingMoneyLoanHistory = makeActionCreator(DOWNLOADING_MONEY_LOAN_HISTORY, 'status');

export const SET_MONEY_LOAN_HISTORY = 'SET_MONEY_LOAN_HISTORY';
export const setMoneyLoanHistory = makeActionCreator(SET_MONEY_LOAN_HISTORY, 'value');

export const SET_MONEY_LOAN_HISTORY_TAPPED_ID = 'SET_MONEY_LOAN_HISTORY_TAPPED_ID';
export const setMoneyLoanHistoryTappedId = makeActionCreator(SET_MONEY_LOAN_HISTORY_TAPPED_ID, 'tappedId');

export const SET_MONEY_LOAN_HISTORY_FILTER = 'SET_MONEY_LOAN_HISTORY_FILTER';
export const setMoneyLoanHistoryFilter = makeActionCreator(SET_MONEY_LOAN_HISTORY_FILTER, 'pageField', 'value');

export const SET_MONEY_LOAN_HISTORY_PAGE = 'SET_MONEY_LOAN_HISTORY_PAGE';
export const setMoneyLoanHistoryPage = makeActionCreator(SET_MONEY_LOAN_HISTORY_PAGE, 'pageField', 'value');

export const SET_MONEY_LOAN_HISTORY_SORT = 'SET_MONEY_LOAN_HISTORY_SORT';
export const setMoneyLoanHistorySort = makeActionCreator(SET_MONEY_LOAN_HISTORY_SORT, 'sortField', 'sortDirection');

// tracking status behandle
export const SET_TRACKING_STATUS_BEHANDLE_SELECTED_TAB = 'SET_TRACKING_STATUS_BEHANDLE_SELECTED_TAB';
export const setTrackingStatusBehandleSelectedTab = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_SELECTED_TAB, 'value');


export const DOWNLOADING_TRACKING_STATUS_BEHANDLE_HISTORIES = 'DOWNLOADING_TRACKING_STATUS_BEHANDLE_HISTORIES';
export const downloadingTrackingStatusBehandleHistories = makeActionCreator(DOWNLOADING_TRACKING_STATUS_BEHANDLE_HISTORIES, 'status');

export const SET_TRACKING_STATUS_BEHANDLE_HISTORIES = 'SET_TRACKING_STATUS_BEHANDLE_HISTORIES';
export const setTrackingStatusBehandleHistories = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_HISTORIES, 'data');

export const SET_TRACKING_STATUS_BEHANDLE_HISTORY_SEARCH_TEXT = 'SET_TRACKING_STATUS_BEHANDLE_HISTORY_SEARCH_TEXT';
export const setTrackingStatusBehandleHistorySearchText = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_HISTORY_SEARCH_TEXT, 'text');

export const SET_TRACKING_STATUS_BEHANDLE_HISTORY_TAPPED_ID = 'SET_TRACKING_STATUS_BEHANDLE_HISTORY_TAPPED_ID';
export const setTrackingStatusBehandleHistoryTappedId = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_HISTORY_TAPPED_ID, 'id');


export const DOWNLOADING_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES = 'DOWNLOADING_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES';
export const downloadingTrackingStatusBehandleOnProgresses = makeActionCreator(DOWNLOADING_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES, 'status');

export const SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES = 'SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES';
export const setTrackingStatusBehandleOnProgresses = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES, 'data');

export const SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_SEARCH_TEXT = 'SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_SEARCH_TEXT';
export const setTrackingStatusBehandleOnProgressSearchText = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_SEARCH_TEXT, 'text');

export const SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_TAPPED_ID = 'SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_TAPPED_ID';
export const setTrackingStatusBehandleOnProgressTappedId = makeActionCreator(SET_TRACKING_STATUS_BEHANDLE_ON_PROGRESS_TAPPED_ID, 'id');


export const DOWNLOADING_PREDEFINED_ORDER = 'DOWNLOADING_PREDEFINED_ORDER';
export const downloadingPredefinedOrder = makeActionCreator(DOWNLOADING_PREDEFINED_ORDER, 'status');

export const SET_PREDEFINED_ORDER = 'SET_PREDEFINED_ORDER';
export const setPredefinedOrder = makeActionCreator(SET_PREDEFINED_ORDER, 'data');

export const SET_PREDEFINED_ORDER_SEARCH_TEXT = 'SET_PREDEFINED_ORDER_SEARCH_TEXT';
export const setPredefinedOrderSearchText = makeActionCreator(SET_PREDEFINED_ORDER_SEARCH_TEXT, 'text');

export const SET_TAPPED_PAYMENT_ORDER_BL_NO = 'SET_TAPPED_PAYMENT_ORDER_BL_NO';
export const setTappedPaymentOrderBlNo = makeActionCreator(SET_TAPPED_PAYMENT_ORDER_BL_NO, 'tappedBlNo');


export const SET_TARIFF_SIMULATION_HOST_BL = 'SET_TARIFF_SIMULATION_HOST_BL';
export const setTariffSimulationHostBL = makeActionCreator(SET_TARIFF_SIMULATION_HOST_BL, 'value');

export const DOWNLOADING_TARIFF_SIMULATION_HOST_BL = 'DOWNLOADING_TARIFF_SIMULATION_HOST_BL';
export const downloadingTariffSimulationHostBL = makeActionCreator(DOWNLOADING_TARIFF_SIMULATION_HOST_BL, 'status');

export const CLEAR_TARIFF_SIMULATION_HOST_BL = 'CLEAR_TARIFF_SIMULATION_HOST_BL';
export const clearTariffSimulationHostBL = makeActionCreator(CLEAR_TARIFF_SIMULATION_HOST_BL);

export const SHOW_HOST_BL_CARD = 'SHOW_HOST_BL_CARD';
export const showHostBLCard = makeActionCreator(SHOW_HOST_BL_CARD, 'visible');

export const SUBMITTING_HOST_BL = 'SUBMITTING_HOST_BL';
export const submittingHostBl = makeActionCreator(SUBMITTING_HOST_BL, 'status');

export const SET_DOC_SUBMISSION_MY_RESPONSES = 'SET_DOC_SUBMISSION_MY_RESPONSES';
export const setDocSubmissionMyResponses = makeActionCreator(SET_DOC_SUBMISSION_MY_RESPONSES, 'value');

export const DOWNLOADING_DOC_SUBMISSION_MY_RESPONSES = 'DOWNLOADING_DOC_SUBMISSION_MY_RESPONSES';
export const downloadingDocSubmissionMyResponses = makeActionCreator(DOWNLOADING_DOC_SUBMISSION_MY_RESPONSES, 'status');

export const CLEAR_DOC_SUBMISSION_MY_RESPONSES = 'CLEAR_DOC_SUBMISSION_MY_RESPONSES';
export const clearDocSubmissionMyResponses = makeActionCreator(CLEAR_DOC_SUBMISSION_MY_RESPONSES, 'value');

export const SET_DOC_SUBMISSION_MY_RESPONSE_DETAIL = 'SET_DOC_SUBMISSION_MY_RESPONSE_DETAIL';
export const setDocSubmissionMyResponseDetail = makeActionCreator(SET_DOC_SUBMISSION_MY_RESPONSE_DETAIL, 'value');

export const DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL = 'DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL';
export const downloadingDocSubmissionMyResponseDetail = makeActionCreator(DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL, 'status');

export const CLEAR_DOC_SUBMISSION_MY_RESPONSE_DETAIL = 'CLEAR_DOC_SUBMISSION_MY_RESPONSE_DETAIL';
export const clearDocSubmissionMyResponseDetail = makeActionCreator(CLEAR_DOC_SUBMISSION_MY_RESPONSE_DETAIL, 'value');

export const SET_DOC_SUBMISSION_MY_RESPONSE_TAPPED_ID = 'SET_DOC_SUBMISSION_MY_RESPONSE_TAPPED_ID';
export const setDocSubmissionMyResponseTappedId = makeActionCreator(SET_DOC_SUBMISSION_MY_RESPONSE_TAPPED_ID, 'value');

export const SET_DOC_SUBMISSION_MY_RESPONSE_SEARCHBAR_TEXT = 'SET_DOC_SUBMISSION_MY_RESPONSE_SEARCHBAR_TEXT';
export const setDocSubmissionMyResponseSearchBarText = makeActionCreator(SET_DOC_SUBMISSION_MY_RESPONSE_SEARCHBAR_TEXT, 'value');

export const DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE = 'DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE';
export const downloadingDocSubmissionMyResponseDetailFile = makeActionCreator(DOWNLOADING_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE, 'status');

export const SET_NEW_DOCUMENT_MY_FORMS = 'SET_NEW_DOCUMENT_MY_FORMS';
export const setNewDocumentMyForms = makeActionCreator(SET_NEW_DOCUMENT_MY_FORMS, 'value');

export const DOWNLOADING_NEW_DOCUMENT_MY_FORMS = 'DOWNLOADING_NEW_DOCUMENT_MY_FORMS';
export const downloadingNewDocumentMyForms = makeActionCreator(DOWNLOADING_NEW_DOCUMENT_MY_FORMS, 'status');

export const CLEAR_NEW_DOCUMENT_MY_FORMS = 'CLEAR_NEW_DOCUMENT_MY_FORMS';
export const clearNewDocumentMyForms = makeActionCreator(CLEAR_NEW_DOCUMENT_MY_FORMS, 'value');

export const SET_NEW_DOCUMENT_MY_FORM_TAPPED_ID = 'SET_NEW_DOCUMENT_MY_FORM_TAPPED_ID';
export const setNewDocumentMyFormTappedId = makeActionCreator(SET_NEW_DOCUMENT_MY_FORM_TAPPED_ID, 'value');

export const SET_NEW_DOCUMENT_MY_FORM_DETAIL = 'SET_NEW_DOCUMENT_MY_FORM_DETAIL';
export const setNewDocumentMyFormDetail = makeActionCreator(SET_NEW_DOCUMENT_MY_FORM_DETAIL, 'value');

export const SET_NEW_DOCUMENT_MY_FORM_FIELD_VALUE = 'SET_NEW_DOCUMENT_MY_FORM_FIELD_VALUE';
export const setNewDocumentMyFormFieldValue = makeActionCreator(SET_NEW_DOCUMENT_MY_FORM_FIELD_VALUE, 'value');

export const DOWNLOADING_NEW_DOCUMENT_MY_FORM_DETAIL = 'DOWNLOADING_NEW_DOCUMENT_MY_FORM_DETAIL';
export const downloadingNewDocumentMyFormDetail = makeActionCreator(DOWNLOADING_NEW_DOCUMENT_MY_FORM_DETAIL, 'status');

export const UPLOADING_NEW_DOCUMENT_MY_FORM_RESPONSE = 'UPLOADING_NEW_DOCUMENT_MY_FORM_RESPONSE';
export const uploadingNewDocumentMyFormResponse = makeActionCreator(UPLOADING_NEW_DOCUMENT_MY_FORM_RESPONSE, 'status');

export const ADD_PAYMENT_ON_BEHALF_LIST = 'ADD_PAYMENT_ON_BEHALF_LIST';
export const addPaymentOnBehalfList = makeActionCreator(ADD_PAYMENT_ON_BEHALF_LIST, 'value');

export const DOWNLOADING_PAYMENT_ON_BEHALF_LIST = 'DOWNLOADING_PAYMENT_ON_BEHALF_LIST';
export const downloadingPaymentOnBehalfList = makeActionCreator(DOWNLOADING_PAYMENT_ON_BEHALF_LIST, 'status');

export const SET_FORM_RESPONSES = 'SET_FORM_RESPONSES';
export const setFormResponses = makeActionCreator(SET_FORM_RESPONSES, 'data');

export const DOWNLOADING_FORM_RESPONSES = 'DOWNLOADING_FORM_RESPONSES';
export const downloadingFormResponses = makeActionCreator(DOWNLOADING_FORM_RESPONSES, 'status');

export const CLEAR_FORM_RESPONSES = 'CLEAR_FORM_RESPONSES';
export const clearFormResponses = makeActionCreator(CLEAR_FORM_RESPONSES);

export const SET_FORM_RESPONSE_DETAIL = 'SET_FORM_RESPONSE_DETAIL';
export const setFormResponseDetail = makeActionCreator(SET_FORM_RESPONSE_DETAIL, 'data');

export const DOWNLOADING_FORM_RESPONSE_DETAIL = 'DOWNLOADING_FORM_RESPONSE_DETAIL';
export const downloadingFormResponseDetail = makeActionCreator(DOWNLOADING_FORM_RESPONSE_DETAIL, 'status');

export const DOWNLOADING_FORM_RESPONSE_DETAIL_FILE = 'DOWNLOADING_FORM_RESPONSE_DETAIL_FILE';
export const downloadingFormResponseDetailFile = makeActionCreator(DOWNLOADING_FORM_RESPONSE_DETAIL_FILE, 'status');

export const SHOW_FORM_RESPONSE_DETAIL = 'SHOW_FORM_RESPONSE_DETAIL';
export const showFormResponseDetail = makeActionCreator(SHOW_FORM_RESPONSE_DETAIL, 'status');

export const SHOW_RESPONSE_FILTER_DIALOG = 'SHOW_RESPONSE_FILTER_DIALOG';
export const showResponseFilterDialog = makeActionCreator(SHOW_RESPONSE_FILTER_DIALOG, 'status');

export const SET_FORM_RESPONSE_FILTER_STATUSES = 'SET_FORM_RESPONSE_FILTER_STATUSES';
export const setFormResponseFilterStatuses = makeActionCreator(SET_FORM_RESPONSE_FILTER_STATUSES, 'data');

export const DOWNLOADING_FORM_RESPONSE_FILTER_STATUSES = 'DOWNLOADING_FORM_RESPONSE_FILTER_STATUSES';
export const downloadingFormResponseFilterStatuses = makeActionCreator(DOWNLOADING_FORM_RESPONSE_FILTER_STATUSES, 'status');

export const CLEAR_FORM_RESPONSE_FILTER_STATUSES = 'CLEAR_FORM_RESPONSE_FILTER_STATUSES';
export const clearFormResponseFilterStatuses = makeActionCreator(
  CLEAR_FORM_RESPONSE_FILTER_STATUSES,
);

export const SET_SELECTED_STATUS_RESPONSE_FILTER = 'SET_SELECTED_STATUS_RESPONSE_FILTER';
export const setSelectedStatusResponseFilter = makeActionCreator(SET_SELECTED_STATUS_RESPONSE_FILTER, 'value');

export const SET_SELECTED_USER_RESPONSE_FILTER = 'SET_SELECTED_USER_RESPONSE_FILTER';
export const setSelectedUserResponseFilter = makeActionCreator(SET_SELECTED_USER_RESPONSE_FILTER, 'value');

export const SET_RESPONSE_FILTER_USER_SEARCH_TEXT = 'SET_RESPONSE_FILTER_USER_SEARCH_TEXT';
export const setResponseFilterUserSearchText = makeActionCreator(SET_RESPONSE_FILTER_USER_SEARCH_TEXT, 'text');

export const SET_TEMP_SELECTED_STATUS_RESPONSE_FILTER = 'SET_TEMP_SELECTED_STATUS_RESPONSE_FILTER';
export const setTempSelectedStatusResponseFilter = makeActionCreator(SET_TEMP_SELECTED_STATUS_RESPONSE_FILTER, 'value');

export const SET_TEMP_SELECTED_USER_RESPONSE_FILTER = 'SET_TEMP_SELECTED_USER_RESPONSE_FILTER';
export const setTempSelectedUserResponseFilter = makeActionCreator(SET_TEMP_SELECTED_USER_RESPONSE_FILTER, 'value');

export const SET_FORM_RESPONSE_ACTIVE_PAGE = 'SET_FORM_RESPONSE_ACTIVE_PAGE';
export const setFormResponseActivePage = makeActionCreator(SET_FORM_RESPONSE_ACTIVE_PAGE, 'page');

export const SET_FORM_RESPONSE_PAGE_SIZE = 'SET_FORM_RESPONSE_PAGE_SIZE';
export const setFormResponsPageSize = makeActionCreator(SET_FORM_RESPONSE_PAGE_SIZE, 'size');

export const SET_FORM_RESPONSE_FILTER = 'FORM_FILTER';
export const setFormResponseFilter = (fieldName, value) => ({
  type: SET_FORM_RESPONSE_FILTER,
  value: {
    [fieldName]: value,
  },
});


export const ADD_ERP_PAYMENT_LOGS = 'ADD_ERP_PAYMENT_LOGS';
export const addErpPaymentLogs = makeActionCreator(ADD_ERP_PAYMENT_LOGS, 'data');

export const DOWNLOADING_ERP_PAYMENT_LOGS = 'DOWNLOADING_ERP_PAYMENT_LOGS';
export const downloadingErpPaymentLogs = makeActionCreator(DOWNLOADING_ERP_PAYMENT_LOGS, 'status');

export const CLEAR_ERP_PAYMENT_LOGS = 'CLEAR_ERP_PAYMENT_LOGS';
export const clearErpPaymentLogs = makeActionCreator(CLEAR_ERP_PAYMENT_LOGS);

export const ADD_ERP_PAYMENT_LOG_DETAIL = 'ADD_ERP_PAYMENT_LOG_DETAIL';
export const addErpPaymentLogDetail = makeActionCreator(ADD_ERP_PAYMENT_LOG_DETAIL, 'data');

export const DOWNLOADING_ERP_PAYMENT_LOG_DETAIL = 'DOWNLOADING_ERP_PAYMENT_LOG_DETAIL';
export const downloadingErpPaymentLogDetail = makeActionCreator(DOWNLOADING_ERP_PAYMENT_LOG_DETAIL, 'status');

export const SHOW_ERP_PAYMENT_LOG_DETAIL = 'SHOW_ERP_PAYMENT_LOG_DETAIL';
export const showErpPaymentLogDetail = makeActionCreator(SHOW_ERP_PAYMENT_LOG_DETAIL, 'status');

export const SHOW_ERP_PAYMENT_LOG_FILTER_DIALOG = 'SHOW_ERP_PAYMENT_LOG_FILTER_DIALOG';
export const showErpPaymentLogFilterDialog = makeActionCreator(SHOW_ERP_PAYMENT_LOG_FILTER_DIALOG, 'status');

export const SET_ERP_PAYMENT_LOG_ACTIVE_PAGE = 'SET_ERP_PAYMENT_LOG_ACTIVE_PAGE';
export const setErpPaymentLogActivePage = makeActionCreator(SET_ERP_PAYMENT_LOG_ACTIVE_PAGE, 'page');

export const SET_ERP_PAYMENT_LOG_PAGE_SIZE = 'SET_ERP_PAYMENT_LOG_PAGE_SIZE';
export const setErpPaymentLogPageSize = makeActionCreator(SET_ERP_PAYMENT_LOG_PAGE_SIZE, 'size');

export const SET_TEMP_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER = 'SET_TEMP_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER';
export const setTempSelectedStatusErpPaymentLogFilter = makeActionCreator(SET_TEMP_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER, 'value');

export const SET_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER = 'SET_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER';
export const setSelectedStatusErpPaymentLogFilter = makeActionCreator(SET_SELECTED_STATUS_ERP_PAYMENT_LOG_FILTER, 'value');

export const SET_PROFORMA_NO_ERP_PAYMENT_LOG_FILTER = 'SET_PROFORMA_NO_ERP_PAYMENT_LOG_FILTER';
export const setProformaNoErpPaymentLogFilter = makeActionCreator(SET_PROFORMA_NO_ERP_PAYMENT_LOG_FILTER, 'value');

export const SHOW_ERP_PAYMENT_LOG_CONFIRM_RETRY_DIALOG = 'SHOW_ERP_PAYMENT_LOG_CONFIRM_RETRY_DIALOG';
export const setErpPaymentLogConfirmRetryDialog = makeActionCreator(SHOW_ERP_PAYMENT_LOG_CONFIRM_RETRY_DIALOG, 'view');

export const RETRYING_ERP_PAYMENT = 'RETRYING_ERP_PAYMENT';
export const retryingErpPayment = makeActionCreator(RETRYING_ERP_PAYMENT, 'status');

export const SET_SELECTED_ERP_PAYMENT_ID = 'SET_SELECTED_ERP_PAYMENT_ID';
export const setSelectedErpPaymentId = makeActionCreator(SET_SELECTED_ERP_PAYMENT_ID, 'value');

export const DOWNLOADING_INVOICE_INFO = 'DOWNLOADING_INVOICE_INFO';
export const downloadingInvoiceInfo = makeActionCreator(DOWNLOADING_INVOICE_INFO, 'status');

export const SET_INVOICE_INFO = 'SET_INVOICE_INFO';
export const setInvoiceInfo = makeActionCreator(SET_INVOICE_INFO, 'data');

export const CLEAR_INVOICE_INFO = 'CLEAR_INVOICE_INFO';
export const clearInvoiceInfo = makeActionCreator(CLEAR_INVOICE_INFO);
